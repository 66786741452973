import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../redux/alertSlice';

const DynamicAlert = ({ message, duration, color }) => {
    const [showAlert, setShowAlert] = useState(true);
    const {alert}=useSelector(state=>state.alert)
    const dispatch = useDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
           dispatch(setAlert({show:false}))
        }, 800);

        return () => clearTimeout(timer);
    }, []);

    const alertClass = `alert alert-${alert?.color}`;

    const styles = {
        alert: {
            padding: '1rem',
            borderRadius: '4px',
            fontSize: '1.2rem',
           // fontWeight: 'bold',
           // color: '#fff',
         //   backgroundColor: '#3f51b5',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            width: '100%',
            margin: '0 auto',
        }
    }

    return (
            <div className={alertClass} role="alert" style={styles.alert}>
                {alert?.message}
            </div>
    );
};

export default DynamicAlert;
