import React from 'react';
import { useNavigate } from 'react-router-dom';
import { postShareLink } from '../functions/link';
import { customRequest } from '../functions/request';
import { MdFileDownload, MdLocationPin, MdPlayCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setShowAlertPopup, setShowHidePostStatusPopup } from '../redux/dataSlice';
import { RWebShare } from 'react-web-share';
import { SUBDOMAIN_NAME } from '../constants/subdomain';
import { getLoggedUser } from '../functions/helper';
import { saveAs } from 'file-saver';
import MakePoster from './MakePoster';
import { Colors } from '../constants/colors';


function PostCard(props) {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    let [upvoteCount, setUpvoteCount] = React.useState(props.post.upvotes);
    let [downvoteCount, setDownVoteCount] = React.useState(props.post.downvotes);
    let navigate = useNavigate();
    let [pinned, setPinned] = React.useState(false);
    let [showPostOptions, setShowPostOptions] = React.useState(false);
    const [buttonColor, setButtonColor] = React.useState(props.post.badge_color);
    const dispatch = useDispatch();
    const videoRef = React.createRef();
    const videoPlayerRef = React.createRef();
    const [imageError, setImageError] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)



    const handlePostVote = (vote) => {

        if (!localStorage.getItem('token')) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message: "Login to upvote or downvote.",
                    showLoginBtn: true
                })
            );
            return;
        }

       
        customRequest('post/votepost', { vote: vote, post_id: props.post.id }).then((res) => {
            if (res.status == "incvote" && res.type == "upvote") {
                setUpvoteCount((upvote) => {
                    return upvote + 1;
                })
            } else if (res.status == "incvote" && res.type == "downvote") {
                setDownVoteCount(downvoteCount + 1)
            } else if (res.status == "decvote" && res.type == "upvote") {
                setUpvoteCount(upvoteCount - 1)
            } else if (res.status == "decvote" && res.type == "downvote") {
                setDownVoteCount(downvoteCount - 1)
            } else if (res.status == "decinc" && res.type == "downvote") {
                setDownVoteCount(downvoteCount - 1)
                setUpvoteCount(upvoteCount + 1)
            } else if (res.status == "decinc" && res.type == "upvote") {
                setUpvoteCount(upvoteCount - 1)
                setDownVoteCount(downvoteCount + 1)
            }

        })
    }


    const handleDeletePost = () => {
        customRequest('post/delete', { postid: props.post.id }).then((res) => {
            document.querySelector('#post-' + props.post.id).style.display = "none";
        })
    }

    const handleSharePost = () => {
        customRequest('post/new-share', { "post_id": props.post.id });
        // window.navigator.share({
        //     title: "Share Localbol Post",
        //     text: props.post.contents[0].text,
        //     url: postShareLink + props.post.slug
        // })
    }


    const handlePinPost = () => {
        customRequest("post/pinpost", { user_id: props.post.user.id, post_id: props.post.id, pin: !pinned }).then((res) => {
            if (res.status == "success") {
                setPinned(!pinned);
                // alert('Post pinned successfully');
            }
        })
    }


    const handleButtonColor = () => {
        if ((upvoteCount + downvoteCount) > 10 && upvoteCount > (upvoteCount + downvoteCount) * 0.5) setButtonColor('green')
        else if ((upvoteCount + downvoteCount) > 10 && downvoteCount > (upvoteCount + downvoteCount) * 0.5) setButtonColor('red')
        else if ((upvoteCount + downvoteCount) > 0) setButtonColor('yellow')
        else setButtonColor('gray')
    }

    const handlePlayVideo = (e) => {
        e.stopPropagation();
        if (videoRef.current !== null) {
            if (videoRef.current.paused) {
                videoPlayerRef.current.style.display = "none";
                videoRef.current.play();
                videoRef.current.setAttribute('controls', 'controls');
            } else {
                videoRef.current.pause();
                videoPlayerRef.current.style.display = "flex";
            }
        }
    }

    const handleOnVideoPlay = (e) => {
        videoPlayerRef.current.style.display = "none";
    }

    const handlePauseVideo = () => {
        videoPlayerRef.current.style.display = "flex";

        if (videoRef.current !== null) {
            if (!videoRef.current.paused) {
                videoPlayerRef.current.style.display = "flex";
                videoRef.current.pause();
            }
        }
    }

    const handleDownload = (link) => {
        if (props?.post?.media[0]?.type === 'image') {
            //  document.body.style.overflow = 'hidden'; // Prevent body scrolling
            setIsOpen(true)
        } else {
            const dynamicName = link?.substring(link.lastIndexOf('/') + 1);
            try {
                if (!dynamicName) {
                    dispatch(
                        setShowAlertPopup(
                            {
                                show: true,
                                title: "Alert",
                                message: "Something went wrong",
                            }
                        )
                    )
                    return;
                }
                saveAs(link, dynamicName)

                customRequest('post/new-download', { post_id: props.post.id })
            } catch (error) {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Something went wrong",
                        }
                    )
                )
            }

        }

    }

    const handleCloseDownloadModal = () => {
        // document.body.style.overflow = 'auto'; // Revert to default scrolling
        setIsOpen(false)

    }




    const handleImageError = () => {
        //  console.log('error in image')
        setImageError(true)

    }

    const handleNavigateToContest = () => {
        if (getLoggedUser()) {
            navigate('/contest', { state: { contest_id: props.post.contest_id } })
        } else {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please login to see the contest.",
                        showLoginBtn: true
                    }
                )
            )
        }
    }


    React.useEffect(() => {
        handleButtonColor();

    }, [upvoteCount, downvoteCount])

    return (
        <div className="postcardmain" id={"post-" + props.post.id}>
            <div className="postcard-header">
                <div className="d-flex flex-column bd-highlight">
                    <div className="p-0 bd-highlight">
                        <div className="d-flex flex-row bd-highlight align-items-center">
                            <div className='d-flex flex-row flex-grow-1' onClick={() => { navigate('/userprofile', { state: { user_id: props.post.user.id } }) }} style={{ cursor: "pointer" }}>
                                <div className="py-1 bd-highlight align-self-start">
                                    <div className="postcard-user-img">
                                        <img
                                            onError={handleImageError}
                                            alt='user_image'
                                            src={imageError ? `${process.env.PUBLIC_URL}/profile_image.png` : `${props?.post?.user?.profile_pic}`}
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="py-1 bd-highlight flex-grow-1">
                                    {
                                        props.post.user
                                            ?
                                            <h5 className="mb-1">{props.post.user.full_name}</h5>
                                            :
                                            "User Name"
                                    }
                                    {/* <h6><Moment format='HH:MM DD/MM/YYYY' date={props.post.contents[0].created_at} /></h6> */}
                                </div>
                            </div>
                            <div className="py-1 bd-highlight align-self-start">
                                {
                                    user !== null && user.id.toString() === props.post.user.id.toString()
                                        ?
                                        <h5 className="mt-2" onClick={() => { handlePinPost() }}>
                                            <i className="far fa-thumbtack gly-rotate-50 mr-3" style={{ color: pinned ? "#6945B4" : "black" }}></i>
                                        </h5>
                                        :
                                        null
                                }
                            </div>
                            <div className="py-1 bd-highlight align-self-start">
                                {
                                    user !== null &&
                                        user.id.toString() === props.post.user.id.toString()
                                        ?
                                        <button type="button" className="btn btn-link p-0">
                                            <i className="far fa-ellipsis-v" style={{ padding: "0 10px" }} onClick={() => { setShowPostOptions(!showPostOptions) }}></i>
                                        </button>
                                        :
                                        null
                                }
                                {
                                    showPostOptions
                                        ?
                                        <div style={{ position: "absolute", right: 8, borderRadius: 5, minWidth: 120, zIndex: 1, backgroundColor: "white", border: "1px solid rgba(0,0,0,0.1)" }}>
                                            <ul style={{ listStyle: "none", marginBottom: 0 }}>
                                                <li onClick={() => { navigate("/editpost/" + props.post.slug) }} style={{ fontSize: 14, fontFamily: "Open Sans", borderBottom: "1px solid rgba(0,0,0,0.1)", padding: "6px 12px", cursor: "pointer" }}>Edit</li>
                                                <li onClick={() => handleDeletePost()} style={{ fontSize: 14, fontFamily: "Open Sans", padding: "6px 12px", cursor: "pointer" }}>Delete</li>
                                            </ul>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bd-highlight" onClick={() => { navigate('/post/' + props.post.slug) }}>
                        <span style={{ fontWeight: "600", fontSize: 15 }}>
                            {props.post.contents[0].title}
                        </span>
                        {
                            props.post?.contest_hashtag
                                ?
                                <div style={{ display: "table", padding: "2px 8px", backgroundColor: "rgba(0,0,0,0.1)", fontSize: 12, marginRight: "auto", borderRadius: 60 }}>
                                    {
                                        props.post?.contest_hashtag?.toString()?.split(",")?.map((tag) => {
                                            return (
                                                <button style={{ marginRight: "auto", border: "none" }}>
                                                    #{tag}
                                                </button>

                                            )
                                        })

                                    }
                                </div>
                                :
                                null
                        }
                        {
                            props.post.contents[0].description
                                ?
                                <span style={{ display: "block", fontSize: 15 }}>
                                    {props.post.contents[0].description}
                                </span>
                                :
                                null
                        }
                    </div>
                </div>
            </div>

            <div className="postcard-img" onClick={() => { navigate('/post/' + props.post.slug) }}>
                {
                    props.post.media === null || props.post.media[0] == undefined
                        ?
                        props.post.contents[0].description && props.post.contents[0].description.includes('https://yout')
                            ?
                            props.post.contents[0].description.match(/\bhttps?:\/\/\S+/gi)[0].includes('be/')
                                ?
                                <iframe loading='lazy' width="100%" height="240" src={"https://www.youtube.com/embed/" + props.post?.contents[0]?.description?.match(/\bhttps?:\/\/\S+/gi)[0]?.split('e/')[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                :
                                <iframe  loading='lazy'  width="100%" height="240" src={"https://www.youtube.com/embed/" + props.post?.contents[0]?.description?.match(/\bhttps?:\/\/\S+/gi)[0]?.split("=")[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>

                            :
                            null
                        :
                        props.post?.media[0]?.type == "video"
                            ?
                            // <video controls="false" className="img-fluid" poster={require("../assets/common_thumbnail.jpg")}>
                            //     <source src={imageRootlink + "/post/" + props.post.media[0].path} type="video/mp4"></source>
                            // </video>
                            <div style={{ position: "relative" }}>
                                <div ref={videoPlayerRef} onClick={(e) => { handlePlayVideo(e) }} style={{ position: "absolute", backgroundColor: "white", height: 60, width: 60, borderRadius: 60, top: "50%", left: "50%", transform: "translate(-50%,-50%)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <MdPlayCircle size={64} color="#6945B4" />
                                </div>
                                <video  onPause={handlePauseVideo} onPlay={handleOnVideoPlay} controls onClick={() => { handlePauseVideo() }} className="img-fluid video-ele" preload="metadata" ref={videoRef} style={{ maxHeight: props.isFullHeight ? "auto" : 240, width: "100%", objectFit: "cover" }}>
                                    <source src={props.post.media[0].path + "#t=0.6"} type="video/mp4"></source>
                                </video>
                            </div>
                            :
                            props.post.media[0].type === "audio"
                                ?
                                <audio src={props.post.media[0].path} controls style={{ borderRadius: 60 }}></audio>
                                :
                                props.post.media[0].type === "link"
                                    ?
                                    props.post.media[0].path.toString().includes('be/')
                                        ?
                                        <iframe loading='lazy' style={{objectFit:"contain"}} width="100%" height="240px" src={"https://www.youtube.com/embed/" + props.post?.media[0]?.path?.split('e/')[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                        :
                                        <iframe  loading='lazy' style={{objectFit:"contain"}} width="100%" height="240px" src={"https://www.youtube.com/embed/" + props.post?.media[0]?.path?.split("=")[1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                    :
                                    <div className='downLoad-image'>
                                        <img onError={(e) => { e.target.src = 'https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148309693.jpg' }} src={props.post.media[0].path} style={{ maxHeight: props.isFullHeight ? "auto" : "400px", objectFit: "cover", width: "100%" }} />
                                    </div>
                }
                {/* <div className="post-view-btn">
                    <i className="fal fa-eye"></i> 1200 Views
                </div> */}
            </div>
            <div className="postcard-footer">
                <div className="d-flex flex-column bd-highlight">
                    {!!props?.post?.location?.locationMaster?.location_name && isNaN(props?.post?.location?.locationMaster?.location_name) &&
                        <div className="py-1 bd-highlight">
                            <div className="d-flex flex-row bd-highlight align-items-center">
                                <div className="bd-highlight me-2" style={{ display: "flex", alignItems: "center" }}>
                                    <MdLocationPin />
                                    {/* <i className="fal fa-location-pin"></i> */}
                                </div>
                                <div className="bd-highlight flex-grow-1">
                                    <h6>Posted In : {props.post?.location?.locationMaster?.location_name}</h6>
                                </div>
                                <div className="bd-highlight">
                                    <button onClick={() => { dispatch(setShowHidePostStatusPopup(true)) }} type="button" style={{ height: 'auto', width: 40, border: 'none', borderRadius: 60, backgroundColor: buttonColor, textTransform: "capitalize" }}>
                                        {/* {props.post.status} */}
                                        <i className="fal fa-info-circle" style={{ fontSize: 14 }}></i>
                                    </button>
                                </div>
                            </div>
                        </div>}
                    <div className="py-1 bd-highlight border-bottom" style={{ display: "flex", overflowX: "auto", width: "100%", height: 40 }}>
                        <button type="button" className="btn btn-dark" onClick={() => { handlePostVote("upvote") }} style={{ whiteSpace: "nowrap" }}>
                            <i className="fal fa-thumbs-up fa-2xx mx-2"></i>
                            <span>{upvoteCount}</span>
                        </button>
                        <button type="button" className="btn btn-dark" onClick={() => { handlePostVote("downvote") }} style={{ whiteSpace: "nowrap" }}>
                            <i className="fal fa-thumbs-down fa-2xx mx-2"></i>
                            <span>{downvoteCount}</span>
                        </button>
                        <button type="button" className="btn btn-dark" onClick={() => { navigate('/post/' + props.post.slug) }} style={{ whiteSpace: "nowrap" }}>
                            <i className="fal fa-comment fa-2xx"></i> {props.post.comments.length}
                        </button>
                        <RWebShare
                            data={{
                                text: props?.post?.contents[0]?.text,
                                url: postShareLink + props.post.slug,
                                title: `Share ${SUBDOMAIN_NAME} Post`,
                            }}
                            onClick={handleSharePost}
                        >
                            <button type="button" className="btn btn-dark">
                                <i className="far fa-sign-out gly-rotate-90 fa-2xx me-2 mr-1"></i>
                                Share
                            </button>
                        </RWebShare>
                        
                        {
                            props?.post?.source?.toLowerCase() === "contest"
                                ?
                                <button style={{ whiteSpace: "nowrap" }} type="button" className="btn btn-dark" onClick={handleNavigateToContest}>
                                    Source : {props.post.source}
                                </button>
                                :
                                props.post.source.toLowerCase() === "web"
                                    ?
                                    <a type="button" href={props.post.source_link} className="btn btn-dark" target="_blank" style={{ whiteSpace: "nowrap" }}>
                                        Source : {props.post.source}
                                    </a>
                                    :
                                    <button type="button" className="btn btn-dark" style={{ whiteSpace: "nowrap" }}>
                                        Source : {props.post.source}
                                    </button>

                        }
                        <button type="button" className="btn btn-dark" style={{ whiteSpace: "nowrap" }}>
                            Mood : {props.post.mood == null ? "" : props.post.mood.mood_name}
                        </button>

                    </div>

                    {props.post.media === null || props.post.media[0] == undefined ||props?.post?.source==="contest"
                        ? null :
                        <div className="py-1 bd-highlight text-center">

                            <button onClick={()=>handleDownload(props.post.media[0].path)} type="button" className="btn btn-link" style={{color:Colors.primary,fontWeight:"600"}} >
                                <MdFileDownload style={{height:"20px",width:"25px"}}/>
                               
                                {/* <i className="far fa-sign-out gly-rotate-180 fa-2xx me-2"></i> */}
                                {props.post.media[0].type == "image" ? "MAKE YOUR POSTER" : "DOWNLOAD"}

                            </button>


                        </div>}
                </div>
            </div>
            {isOpen && <MakePoster onClose={handleCloseDownloadModal} isOpen={isOpen} postId={props?.post?.id} postImageUrl={props?.post?.media[0]?.path} />
            }
        </div>
    )
}


export {
    PostCard
}