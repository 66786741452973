import { customRequest, postApiService } from "./request";

export const getUserDetails = (user_id) => {
  return customRequest("user/userdetail", { user_id: user_id });
};


export const fetchPost = (body) => {
  return postApiService("post/allposts", body);
};
