import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { MdCloudUpload } from 'react-icons/md';
import IMAGE_PATH from '../assets';
import { Colors } from '../constants/colors';
import { emailRegex, phoneRegex, replaceSpacesWithNBSP, saveUser } from '../functions/helper';
import CommunityCard from './CommunityCard';
import CustomInput from './CustomInput';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { customMultipartRequest } from '../functions/request';
import { setShowAlertPopup } from '../redux/dataSlice';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../functions/services';
import { gradient } from '../constants/templateGradient';
import { MdArrowForward, MdComputer, MdEmail, MdLocationPin, MdOutlineMessage, MdPhone, MdWeb } from 'react-icons/md';
import SpinnerOverlay from './OverlayLoader';

const MakePoster = ({ isOpen, onClose, postImageUrl, postId }) => {
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')) ?? null);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [imageRefrence, setImageRefrence] = useState(null);
    const [userPic, setUserPic] = useState(null);
    const dispatch = useDispatch()
    const [showTemplate, setShowTemplate] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [imgError, setImgError] = useState(false)
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        name: user?.full_name ? user?.full_name : "",
        mobile: user?.phone ? user?.phone : '',
        businessName: user?.business_name ? user?.business_name : '',
        message: '',
        alternateEmail: user?.alternate_email ? user?.alternate_email : "",
        website: user?.website ? user?.website : '',
        address: user?.alternate_address ? user?.alternate_address : '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [profilePicture, setProfilePicture] = useState(null)

    const handleProfileImage = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Read the selected file and create a preview URL
            const reader = new FileReader();

            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };

            reader.readAsDataURL(file);
            setProfilePicture(file);
        }


    };


    const getImageRef = (imageRef) => {
        setImageRefrence(imageRef)
        // return imageRef
    }

    const refreshUserDetails = async () => {
        getUserDetails(user?.id).then((res) => {
            if (!!res.user) {
                saveUser(res.user)
                setUser(res.user)
            }

        });
    }

    function showAlertPopup(message) {
        dispatch(
            setShowAlertPopup({
                show: true,
                title: 'Alert',
                message,
            })
        );
    }

    const handleSubmit = async () => {
        if (!selectedTemplate?.id) {
            showAlertPopup("Please choose template")
            return;
        }
        if (!user && !formData?.name) {
            showAlertPopup("Please enter your name")
            return;
        }
        if (!formData.mobile) {
            showAlertPopup("Please enter phone number")
            return
        }

        if (!phoneRegex.test(formData.mobile)) {
            showAlertPopup("Invalid phone!")
            return;
        }

        if (!user && !formData?.alternateEmail) {
            showAlertPopup("Please enter your email")
            return;
        }
        if (formData?.alternateEmail && !emailRegex.test(formData.alternateEmail)) {
            showAlertPopup("Invalid email!")
            return;
        }
        setLoading(true)
        const element = imageRefrence?.current;
        element.style.display = "block"
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/jpg');
        saveAs(data, 'my.fittest.club.jpg')
        let reqformdata = new FormData();
        reqformdata.append('post_id', postId);
        reqformdata.append('user_id', user ? user?.id : null);
        reqformdata.append('isGuest', user ? false : true);
        reqformdata.append('name', formData?.name);
        reqformdata.append('alternateEmail', formData?.alternateEmail);
        reqformdata.append('mobile', formData?.mobile);
        reqformdata.append('businessName', formData?.businessName);
        reqformdata.append('jobTitle', formData?.message)
        reqformdata.append('address', formData?.address)
        reqformdata.append('website', formData?.website)
        reqformdata.append('profile', profilePicture)
        customMultipartRequest('post/new-download', reqformdata)
            .then(async (res) => {
                //     console.log(res)
                if (res.status === 'success' && user) {
                    await refreshUserDetails()
                }
            })
            .catch(e => {
                //  console.log(e) 
            }
            )
            .finally(() => { 
                setLoading(false);
                onClose() 
            })


    }

    async function getBase64ImageFromUrl(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
            });
        } catch (error) {
            throw error;
        }
    }






    useEffect(() => {
        if (!!user?.user_detail?.profile_pic) {
            getBase64ImageFromUrl(user?.user_detail?.profile_pic)
                .then((base64data) => {
                    setUserPic(base64data)
                    // You can use the base64data wherever you need it
                })
                .catch((error) => {
                    //    console.error('Error fetching image:', error);
                });
        }
        const loadImage = async () => {
            getBase64ImageFromUrl(postImageUrl)
                .then((base64data) => {
                    setImageSrc(base64data);
                    // You can use the base64data wherever you need it
                })
                .catch((error) => {
                    //  console.error('Error fetching image:', error);
                });
        }

        loadImage()

    }, [])


    const handleSelectTemplate = (elm) => {
        setSelectedTemplate(elm)
        // console.log(elm)
    }


    if (!isOpen) return null;


    const modalStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //  height:'80%'
        //   maxHeight:'80vh',

    };


    const backdropStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '999',
        backdropFilter: 'blur(2px)',



    };

    const contentStyle = {
        backgroundColor: Colors.secondary,
        padding: '20px',
        Width: '80vw',
        //min
        zIndex: '9999',
        borderRadius: '8px',




    };

    return (
        <div >
            <CommunityCard selectedTemplate={selectedTemplate} getImageRef={getImageRef} imageSrc={imageSrc} profilePic={imagePreview ? imagePreview : userPic} data={formData} />
            <div >
                <div style={backdropStyle}>
                    <div style={modalStyle}>
                        <div style={contentStyle}>
                            {!showTemplate && <>
                                <div className='my-2 d-flex'>
                                    <div>
                                        <input type="file" style={{ display: "none" }} id="selectMedia" name="media" onChange={handleProfileImage} />
                                        {imagePreview ?
                                            <img
                                                src={imagePreview}
                                                style={{ width: '80px', height: '80px', borderRadius: '40px', backgroundColor: Colors.white, marginRight: 10, objectFit: "contain" }}
                                            />
                                            :
                                            !imgError && user?.user_detail?.profile_pic ? (
                                                <img
                                                    onError={(e) => setImgError(true)}
                                                    src={user?.user_detail?.profile_pic}
                                                    style={{ width: '80px', height: '80px', borderRadius: '40px', backgroundColor: Colors.white, marginRight: 10, objectFit: "contain" }}
                                                />
                                            ) : (
                                                <img
                                                    src={IMAGE_PATH.Logo}
                                                    style={{ width: '80px', height: '80px', borderRadius: '40px', backgroundColor: Colors.white, marginRight: 10, objectFit: "contain" }}
                                                />
                                            )
                                        }
                                        <label className='btn ' htmlFor="selectMedia" onClick={() => { }} style={{ maxWidth: '300px', border: `1px solid ${Colors.primary}`, padding: "4px 10px", borderRadius: '150px', alignItems: "center", justifyContent: "center", fontSize: 12, color: Colors.primary, backgroundColor: "white", whiteSpace: "nowrap", cursor: "pointer" }}>
                                            <MdCloudUpload color={Colors.primary} style={{ marginRight: '4px' }} />
                                            Change
                                        </label>
                                    </div>
                                    <span style={{ cursor: 'pointer', fontSize: 25, marginLeft: 'auto', marginTop: -20 }} onClick={onClose}>
                                        &times;
                                    </span>


                                </div>

                                <div className='d-flex space-between' style={{}} >
                                    <CustomInput className={"mr-2"} type="text" placeholder="Name" value={formData.name} name={'name'}
                                        onChange={handleChange} />
                                    <CustomInput icon={<MdPhone color={Colors.primary} />} type="number" placeholder="Mobile*" value={formData.mobile}
                                        name={'mobile'} onChange={handleChange} />

                                </div>
                                {/* <div className='d-flex space-between mt-2 ' style={{}} >
    <CustomInput customStyle={{ marginRight: "6px" }} icon={<MdPhone color={Colors.primary} />} className="mr-2" type="number" placeholder="Mobile 1*" value={formData.mobile}
        name={'mobile'} onChange={handleChange} />
   
</div> */}
                                <div>
                                    <CustomInput icon={<MdComputer color={Colors.primary} />} type={'text'} placeholder='Enter Your Business/profession' className="mt-2" value={formData.businessName} name={'businessName'}
                                        onChange={handleChange} />
                                    <CustomInput icon={<MdLocationPin color={Colors.primary} />} type={'text'} placeholder='Address' className="mt-2" value={formData.address}
                                        name={'address'} onChange={handleChange} />

                                    <CustomInput icon={<MdEmail color={Colors.primary} />} type={'email'} placeholder='Alternate Email' className="mt-2" value={formData.alternateEmail}
                                        name={'alternateEmail'} onChange={handleChange} />

                                    <CustomInput icon={<MdWeb color={Colors.primary} />} type={'url'} placeholder='Website Address' className="mt-2" value={formData.website}
                                        name={'website'} onChange={handleChange} />


                                    <CustomInput icon={<MdOutlineMessage color={Colors.primary} />} type={'text'} placeholder='Write a message' className="mt-2" value={formData.message}
                                        name={'message'} onChange={handleChange} />

                                </div>
                                <div className='text-center mt-2 '>
                                    {/* <button onClick={onClose} className="btn-secondary btn rounded mr-2" style={{ color: Colors.white }}>Cancel</button> */}
                                    <button disabled={formData?.mobile ? false : true} onClick={() => setShowTemplate(true)} className="btn-secondary btn rounded mr-2" style={{ color: Colors.white }}>Choose Template</button>
                                    <button onClick={handleSubmit} className="btn rounded " style={{ backgroundColor: Colors.primary, color: Colors.white, alignSelf: 'center' }}>
                                        Download
                                   </button>
                                </div>
                            </>
                            }
                            {showTemplate &&

                                <div className="d-flex flex-column justify-content-between" style={{ width: '80vw', maxWidth: "400px" }}>
                                    <p className='text-center'>Choose Template</p>
                                    <div style={{ height: "400px", overflow: 'scroll' }}>
                                        {gradient.map(elm => (
                                            <div onClick={() => handleSelectTemplate(elm)}
                                                className={`my-2 ${selectedTemplate?.id === elm.id ? 'selected' : ''}`} // Add a 'selected' class when the element is selected
                                                key={elm.id}
                                                style={{
                                                    width: '100%', height: '100px',
                                                    background: `linear-gradient(45deg,${elm.gradient.join(', ')})`,
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px"
                                                }}>

                                            </div>
                                        ))}
                                    </div>
                                    <button onClick={() => setShowTemplate(false)} className="btn rounded" style={{ backgroundColor: Colors.primary, color: Colors.white, alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                                        <p className='m-0'>Continue</p>
                                        <MdArrowForward style={{ marginLeft: '6px' }} />
                                    </button>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loading && <SpinnerOverlay/>}
        </div>
    );
};

export default MakePoster;

// <div style={{
//     color: Colors.white,
// }}>

//     <p style={{ maxLines: 1, fontSize: '6px', color: Colors.white, textAlign: 'center', margin: 0 }}>{replaceSpacesWithNBSP("Created with my.fittest.club")}</p>

//     <div style={{ display: 'flex', justifyContent: "space-between" }}>
//         <div >
//             <p style={{
//                 color: '#FFF',
//                 fontFamily: 'Oregano',
//                 fontSize: '1rem',
//                 fontStyle: 'normal',
//                 fontWeight: 400,
//                 margin: 0


//             }}>{formData.message}</p>
//             <p style={{
//                 color: '#FFF',
//                 fontFamily: 'Oregano',
//                 fontSize: '1rem',
//                 fontStyle: 'normal',
//                 fontWeight: 400,
//                 margin: 0


//             }}>{formData.message}</p>  <p style={{
//                 color: '#FFF',
//                 fontFamily: 'Oregano',
//                 fontSize: '1rem',
//                 fontStyle: 'normal',
//                 fontWeight: 400,
//                 margin: 0


//             }}>{formData.message}</p>
//         </div>
//         <div>
//             <p>{formData.businessName}</p>
//             <div style={{ display: 'flex', alignItems:'center' }}>
//                 <h6 style={{marginRight:'1rem'}}>{formData.name}</h6>
//                 {!!user?.user_detail?.profile_pic ? <img src={userPic} style={{ width: '45px', height: '45px', borderRadius: '30px' }} />
//                     : <div className="text-center" style={{ width: '45px', height: '45px', borderRadius: '35px' }}>
//                         <img src={IMAGE_PATH.Logo} style={{ width: '45px', height: '45px', borderRadius: '30px', backgroundColor: Colors.white, objectFit: 'contain' }} />
//                      </div>
//                 }
//             </div>
//         </div>

//     </div>

//     {/* {!!user?.user_detail?.profile_pic ? <img src={userPic} style={{ width: '60px', height: '60px', borderRadius: '30px' }} />
//         : <div className="text-center" style={{ width: '70px', height: '70px', borderRadius: '35px' }}>
//             <img src={IMAGE_PATH.Logo} style={{ width: '60px', height: '60px', borderRadius: '30px', backgroundColor: Colors.white, objectFit: 'contain' }} />
//         </div>
//     }
//     <div className="ml-2" style={{ zIndex: 10 }}>
//         {formData?.name && <> <span style={{ color: Colors.white, fontSize: 14, whiteSpace: 'nowrap', }}>{formData?.name}</span>
//             <br /></>}
//         {formData?.businessName && <> <span style={{ color: Colors.white, fontSize: 12, whiteSpace: 'nowrap', }}>{formData?.businessName}</span>
//             <br /></>}
//         {formData?.message && <span style={{ color: Colors.white, fontSize: 12, fontStyle: 'italic', whiteSpace: 'nowrap', }}>{formData?.message}</span>}
//     </div> */}


// </div>
// <div style={{
//     color: Colors.white,
//     fontSize: '16px'
// }}>
//     <div style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
//         <span style={{ maxLines: 1, fontSize: '6px', fontStyle: 'italic', color: Colors.white }}>{replaceSpacesWithNBSP("Powered by my.fittest.club")}</span>
//     </div>
//     <div className="d-flex flex-column "  >

//         {formData?.mobile && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
//             <img src={IMAGE_PATH.Phone} style={{ width: 18, height: 18, marginLeft: '2px' }} />
//             <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{formData?.mobile}</span>
//         </div>}
//         {formData?.alternateEmail && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
//             <img src={IMAGE_PATH.Email} style={{ width: 18, height: 18, marginLeft: '2px' }} />
//             <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{formData?.alternateEmail}</span>
//         </div>}
//         {formData?.website && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
//             <img src={IMAGE_PATH.Website} style={{ width: 18, height: 18, marginLeft: '2px' }} />
//             <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{formData?.website}</span>
//         </div>}
//         {formData?.address && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
//             <img src={IMAGE_PATH.Location} style={{ width: 18, height: 18, marginLeft: '2px' }} />
//             <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{replaceSpacesWithNBSP(formData?.address)}</span>
//         </div>}
//     </div>



// </div>