import React from "react";
import { useNavigate } from "react-router-dom";
import { SUBDOMAIN_ID } from "../../constants/subdomain";
import { rootlink } from "../../functions/link";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { getLoggedUser, saveUser, saveUserToken } from "../../functions/helper";
import GoogleButton from "../../components/GoogleButton";
import { setShowAlertPopup } from "../../redux/dataSlice";
import { Colors } from "../../constants/colors";
import Footer from "../../components/Footer";
import CustomButton from "../../components/CustomButton";
import apiPath from "../../constants/apiPath";


export default function LoginScreen() {
  let [phoneNumber, setPhoneNumber] = React.useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { redirectToContestId } = useSelector(state => state.data)

  const handleInput = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleLogin = async (data) => {
    const url = `${rootlink}/auth/login-with-google`
    // console.log(rootlink)
    try {
      const resp = await axios.post(url, data, {
        headers: {
          Accept: 'application/json',
          subdomain: SUBDOMAIN_ID,
        },
      },)
      if (resp.data.status == "success" && !!resp.data.user) {
        saveUser(resp.data?.user)
        saveUserToken(resp.data?.token);
        if (redirectToContestId) {
          navigate(`/contest/${redirectToContestId}'`)
        } else {
          navigate('/home');

        }
      } else if (resp.data.message === 'Invalid credentials.') {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: resp.data.message,
              registerBtn: true
            }
          ))
      }


    } catch (error) {
     // console.log(error)
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        ))
    }


  }



  const handleGoogleAuth = useGoogleLogin({

    onSuccess: async tokenResponse => {
      //  console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get(apiPath.googleAuth, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data).catch(e => {
          console.log(e)
          dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Something went wrong",
              }
            ))
        }
        )
      if (!!userInfo) {
        const body = {
          "email": userInfo?.email,
          "google_id": userInfo?.sub
        }
        await handleLogin(body)
      }
    },
  })


  React.useEffect(() => {
    const user = getLoggedUser()
    //console.log('logged',user)
    if (user !== null && user !== undefined) {
      navigate('/home')
      return;
    }
    // };
  }, [])








  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
      {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
      <div className='p-4 m-4'>

        <img src={require("../../assets/main_logo.png")} style={{ height: "25vh", width: "100%", zIndex: 2, objectFit: "contain" }} />
      </div>
      <span onClick={() => { navigate('/contestlist') }} style={{ color: "black", position: "absolute", top: 20, right: 20, fontSize: 14, fontFamily: "Open Sans, sans-serif", zIndex: 2, cursor: "pointer" }}>Sign up Later</span>
      <div style={{ height: "56%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
        <div className="px-3 py-4">

              <div  style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
               <h5 style={{ lineHeight: 1, fontFamily: "Open Sans" }}><b style={{ color: "#6945B4" }}>Welcome</b> <b>Back</b></h5>
               <span style={{ fontSize: 14, fontFamily: "Open Sans" }}>Enter your login credentials entered at time of account creation</span>
               <div className="mt-4">
                <GoogleButton  onClick={handleGoogleAuth} label={"Sign in with"} />

                <CustomButton className={"btn-block"}  label={"Sign in with Email or WhatsApp"} onClick={() => navigate("/login")} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <hr style={{ flex: 2 }} />
                  <p style={{ color: Colors.grey, flex: 0.5, textAlign: 'center', marginTop: 10 }}>OR</p>
                  <hr style={{ flex: 2 }} />
                </div>
                <CustomButton onClick={() => navigate('/create-account')} label={"Create New Account"} className={"btn-block"} color={Colors.secondary} />
                </div>
                {/* <GoogleButton label={'Sign up with'} onClick={() => navigate("/signup-with-google")} /> */}
                {/* <button className="py-2 btn  d-flex justify-content-center align-items-center " style={{ marginTop: 12, borderRadius: 60, display: 'flex', flex: 1, fontSize: 16,  borderWidth: 1, borderColor: Colors.primary, color: Colors.primary ,fontWeight:"500" }}>
                  Register Business
                </button> */}
              </div>


       
        </div>
      </div>
      <Footer />
    </div>
  );
}
