const CommunityCard = require('./community_card.png');
const Email = require('./email.png');
const Website = require('./website.png');
const Phone = require('./phone.png');
const Logo = require('./main_logo.png');
const Location = require('./address_icon.png');


 const IMAGE_PATH={
    CommunityCard,
    Email,
    Website,
    Phone,
    Logo,
    Location

}

module.exports = IMAGE_PATH;