import React from 'react';
import {  customRequestGET } from '../functions/request';
import { Link, useNavigate } from 'react-router-dom';
import { PostCard } from '../components/postCard';

export default function AllPostsScreen(){
  let [posts,setPosts] = React.useState(null);
  const navigate = useNavigate();

  const getPosts = ()=>{
    customRequestGET('posts').then((res)=>{
      setPosts(

        res.data.map((post)=>{
          return (
            <PostCard key={post.id} post={post}/>
          )
        })
      )
    })
  }

  React.useEffect(()=>{
    getPosts();
  },[])


    return (
        <React.Fragment>
            <div className="main_warp">
      <nav
        className="navbar sticky-top navbar-light mob-header-bar pb-0"
        style={{display: 'block'}}
        >
        <div className="pt-3 loactiontext">
          <div className="d-flex flex-row bd-highlight w-100">
            <div className="py-1 bd-highlight align-self-center">
              <span className="left-icons"  onClick={()=>{navigate(-1)}}>
              <i className="far fa-arrow-left fa-lg" style={{color: "#605e5c"}}></i>
              </span>
            </div>
            <div className="py-1 bd-highlight loactiontext align-self-center flex-grow-1">
              <h5>Mumbai</h5>
            </div>
            <div className="py-1 bd-highlight align-self-center">
              <a className="search-iconstwo" href="#">
              <img width="25" height="25" src="assets/images/NavIcons.svg" />
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className=" px-0">
        <div className="productdetail">
          <div className="productdetailsmallnav">
            <Link to="/info">Info</Link>
            <a href="Trending.html">Trending Posts</a>
            <a className="productdetailsmallnavactive" href="Allposts.html">All Posts</a>
            <Link to="/faq">FAQs</Link>
            <Link to="/authorities">Local Authorities</Link>
          </div>
          <div
            className="productdetailsmallview"
            id="#Trending"
            >
            {
              posts
            }
            
          </div>
        </div>
      </div>
    </div>
        </React.Fragment>
    )
}