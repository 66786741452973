import React from 'react'
import Spinner from "react-spinkit";
import { Colors } from '../constants/colors';

const Loader = () => {
  return (
    <div style={{ height: "100vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Spinner name="ball-scale-multiple" color={Colors.purple500} />
    </div>
  )
}

export default Loader
