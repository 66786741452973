import React from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '../constants/colors';

const Footer = () => {
    return (
        <div  style={{padding: "20px", position: "fixed", bottom: 0, left: 0, width: "100%", textAlign: "center",zIndex:10,backgroundColor:Colors.lightGrey}}>
            <a href="https://fittest.club/pages/community-of-champions" style={{ color: Colors.primary, marginRight: "20px", }}>About</a>
            <Link to="/terms-and-condition" style={{ color: Colors.primary,marginRight: "20px" }}>T&C</Link>
            <Link to="/privacy-policy" style={{ color: Colors.primary }}>Privacy Policy</Link>

        </div>
    );
};

export default Footer;