import React from "react";
import { useNavigate } from "react-router-dom";
import { customRequest } from "../../functions/request";
import { MdTranslate } from "react-icons/md"
import axios from "axios";
import { setShowAlertPopup } from "../../redux/dataSlice";
import { useDispatch } from "react-redux";
import { SUBDOMAIN_ID } from "../../constants/subdomain";
import { rootlink } from "../../functions/link";
import { handleErrors } from "../../functions/helper";
import CustomButton from "../../components/CustomButton";

export default function LanguageSelectionScreen() {
    let navigate = useNavigate();

    //   const location = useLocation();
    const [languages, setLanguages] = React.useState(null);
    const [selectedLanguageId, setSelectedLanguageId] = React.useState(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);


    // const handleSkip = () => {
    //     navigate("/locationSelectionScreen");
    // }



    const handleRegister = async (data) => {
        const url = `${rootlink}/auth/register-with-google`;
        try {
            const resp = await axios.post(url, data, {
                headers: {
                    Accept: 'application/json',
                    subdomain: SUBDOMAIN_ID,
                },
                timeout: 12000,
            });
            //  console.log('resp',resp.data);
            if (resp.data.status === 'success') {
                dispatch(
                    setShowAlertPopup({
                        show: true,
                        title: "Alert",
                        message: resp.data.message,
                        // showLoginBtn: true
                    })
                )
                navigate('/');
            }
        } catch (error) {
            handleErrors(error)
        }
    };


    const handleGetStarted = async () => {
        setIsLoading(true)
        let userDetail = localStorage.getItem('register_user_data')
        userDetail = JSON.parse(userDetail);
        let registrationData = {
            language_id: selectedLanguageId ? selectedLanguageId : 2,
            ...userDetail
        }


        try {
            await handleRegister(registrationData)
        } catch (error) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message: error.message,
                    showLoginBtn: true
                })
            )
        } finally {
            setIsLoading(false)
        }



        // customRequest("auth/register-with-google", registrationData).then((res) => {
        //     console.log(res)
        //     if (res.status && res.status == "success") {
        //         localStorage.setItem('user', JSON.stringify(res.user));
        //         localStorage.setItem('token', res.token)
        //         navigate("/home");
        //     } else {
        //         alert(res.message);
        //     }
        // }).catch(e=>alert('Some error occured')).finally(()=>setIsLoading(false));

    };

    // const handleGetStarted = () => {
    //     let userDetail = localStorage.getItem('register_user_data')
    //     userDetail = JSON.parse(userDetail);
    //     let registrationData = {
    //         language_id: selectedLanguageId,
    //         ...userDetail
    //     }

    //     customRequest("auth/register", registrationData).then((res) => {
    //         if (res.status && res.status == "success") {
    //             localStorage.setItem('user', JSON.stringify(res.user));
    //             localStorage.setItem('token', res.token)
    //             navigate("/home");
    //         } else {
    //             alert(res.message);
    //         }
    //     });

    // };

    const handleLanguageSelect = (e) => {
        setSelectedLanguageId(e.target.value)
    }

    const getLanguages = () => {
        customRequest('auth/subdomain-languages').then((res) => {
            // console.log(res?.languages)
            setSelectedLanguageId(res?.languages[0]?.id);
            setLanguages(
                res.languages
            )
        }).catch(e => console.log('error in language', e.message))
    }

    React.useEffect(() => {
        getLanguages();
    }, [])

    // const handleGetStarted = () => {
    //     navigate("/locationSelectionScreen");
    // };

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
            {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
            {/* <img src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain" }} /> */}
            <div className='p-4 m-4'>
                <img src={require("../../assets/main_logo.png")} style={{ height: "25vh", width: "100%", zIndex: 2, objectFit: "contain" }} />
            </div>
            {/* <img src={require("../../assets/logo.png")} style={{ position: "absolute", top: "20%", left: "50%", transform: "translateX(-50%)", zIndex: 2, height: "36px" }} /> */}
            <div style={{ height: "54%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
            <div className="mt-4 py-4 px-3" style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
                    <h4 style={{ fontSize: 19 }}>
                        <b style={{ fontFamily: "Open Sans" }}><span style={{ color: "#6945B4" }}>Which languages</span> do you speak or know ?</b><br />
                    </h4>
                    <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "gray" }}>Get local updates based on languages you know</span>

                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>Your Preferred Languages</label>
                            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>(Optional)</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                            <MdTranslate />
                            {
                                languages === null || languages.length == 0
                                    ?
                                    <span style={{ fontSize: 12, marginLeft: 12 }}>English (default)</span>
                                    :
                                    <select onChange={(e) => { handleLanguageSelect(e) }} style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent", border: "none" }}>
                                        {
                                            languages?.map((lang, index) => {
                                                return (
                                                    <option key={index} value={lang.id}>{lang?.language_name}</option>
                                                )
                                            })
                                        }
                                    </select>

                            }
                            {/* <MdChevronRight style={{ marginLeft: "auto" }} /> */}
                        </div>
                    </div>
                    <CustomButton disabled={isLoading ? true : false} className={"btn-block mt-4"} label={"Get Started"} onClick={handleGetStarted} />
                    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 28 }}>
                        <button onClick={handleGetStarted} style={{ borderRadius: 60, backgroundColor: "#6945B4", border: "none", width: "100%", fontFamily: "Open Sans", fontSize: 14, color: "white", padding: "7px" }}>
                            {isLoading?
                             <div className="spinner-border text-white" role="status" style={{width:20,height:20}}>
                             </div>
                             : "Get Started"
                              }
                        </button>
                    </div> */}
                    {/* <Link to="/register" className="text-center mt-2">New User ? Register Here</Link> */}
                </div>
            </div>
        </div>
    );
}
