import { useState } from "react";
import { useRef } from "react";
import IMAGE_PATH from "../assets";
import { Colors } from "../constants/colors";
import { replaceSpacesWithNBSP } from "../functions/helper";

const styles = {
    wrapper: {
        width: '100%',
        height: '130px',
        background: Colors.primary,
        display: 'flex',
    },
    div1: {
        position: 'relative'
        // width: 'calc(45% - 10%)',
        // height: '100%',
        // background: Colors.primary,
    },
    div2: {
        color: Colors.white,
        fontSize: '16px',
        // width: 'calc(55% + 10%)',
        // height: '100%',
        // background: Colors.secondary,
        // borderLeft: `120px solid ${Colors.primary}`,
        // borderBottom: '130px solid transparent', // Change this line
        // boxSizing: 'border-box',
    },
    content: {
        color: 'white',
        fontSize: '16px',
        padding: '22px 0px 6px 0px',
        display: 'flex',
        flexDirection: "row"
    },
    gradientStyles: {
        width: '100%',
        height: 'auto',
        // background:'transparent',

        //    background: 'linear-gradient(45deg,"#B08FFD", "#8386FE", #7C82FF,#7D79FF,#8161FF,#883AFF,#8F15FF)',
        // background: 'linear-gradient(45deg, #6CC3F4, #7FAAE9, #B26DCD,#FA15A6)',
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between'

    }
};

function CommunityCard({ imageSrc, getImageRef, profilePic, data,selectedTemplate }) {
    const imageRef = useRef(null);
    const [imageError, setImageError] = useState(false)


    const handleImageError = () => {
        setImageError(true)
    }



    const handleImageLoad = () => {
        if (imageRef?.current) {
            getImageRef(imageRef);
        }
    };



    


    return (
        <div ref={imageRef} id='download-content' style={{ position: 'absolute', display: 'none',  left: "-9999px",maxWidth:'480px' }}>
            <img onLoad={handleImageLoad} alt='image' src={imageSrc} style={{ height: 'auto', objectFit: 'cover', width: '100%' }} />
            <div style={{
                ...styles.gradientStyles, background: `linear-gradient(45deg,${selectedTemplate?.gradient?.join(', ')})`,
                paddingLeft:"5px",
                paddingRight:"5px"
            }}>
                <div style={styles.content}>
                    {profilePic ? <img  src={profilePic} style={{ width: '60px', height: '60px', borderRadius: '30px' }} />
                        : <div className="text-center" style={{ width: '70px', height: '70px', borderRadius: '35px' }}>
                            <img src={IMAGE_PATH.Logo} style={{ width: '60px', height: '60px', borderRadius: '30px', backgroundColor: Colors.white, objectFit: 'contain' }} />
                        </div>
                    }
                    <div className="ml-2" style={{ zIndex: 10 }}>
                        {data?.name && <> <span style={{ color: Colors.white, fontSize: 14, whiteSpace: 'nowrap', }}>{data?.name}</span>
                            <br /></>}
                        {data?.businessName && <> <span style={{ color: Colors.white, fontSize: 12, whiteSpace: 'nowrap', }}>{data?.businessName}</span>
                            <br /></>}
                        {data?.message && <span style={{ color: Colors.white, fontSize: 12, fontFamily: 'Oregano', whiteSpace: 'nowrap', }}>{data?.message}</span>}
                    </div>


                </div>
                <div style={styles.div2}>
                    <div style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
                        <span style={{ maxLines: 1, fontSize: '6px', fontStyle: 'italic', color: Colors.white }}>{replaceSpacesWithNBSP("Powered by my.fittest.club")}</span>
                    </div>
                    <div className="d-flex flex-column "  >

                        {data?.mobile && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
                            <img src={IMAGE_PATH.Phone} style={{ width: 18, height: 18, marginLeft: '2px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{data?.mobile}</span>
                        </div>}
                        {data?.alternateEmail && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
                            <img src={IMAGE_PATH.Email} style={{ width: 18, height: 18, marginLeft: '2px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{data?.alternateEmail}</span>
                        </div>}
                        {data?.website && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
                            <img src={IMAGE_PATH.Website} style={{ width: 18, height: 18, marginLeft: '2px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{data?.website}</span>
                        </div>}
                        {data?.address && <div className="" style={{ width: '100%', direction: 'rtl', textAlign: 'right', paddingRight: '5px', display: 'inline-flex' }}>
                            <img src={IMAGE_PATH.Location} style={{ width: 18, height: 18, marginLeft: '2px' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{replaceSpacesWithNBSP(data?.address)}</span>
                        </div>}
                    </div>



                </div>
            </div>
        </div>
    );
}

export default CommunityCard;
