import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import './index.css';
import 'swiper/css/effect-cards';

import { EffectCards } from 'swiper/modules';


const SwipeComponent = ({ slides, setActiveSlideData }) => {

    const handleSlideChange = (swiper) => {
        const activeSlideData = slides[swiper.activeIndex];
        setActiveSlideData(activeSlideData)
    };

   

    return (
        <Swiper
            effect={'cards'}
            grabCursor={true}
            modules={[EffectCards]}
            initialSlide={0} // Set the starting index (0 for the first slide)
            speed={0.8}
            className="mySwiper"
            onSlideChange={handleSlideChange}
           // onSwiper={(swiper) => setActiveSlideData(slides[0])}

        >
            {slides?.map((elm) => (
                <SwiperSlide key={elm?.id} >
                    <img
                         loading='lazy'
                        src={elm?.file_name}
                        alt={`Image ${elm?.id}`}
                        className='image-fluid'
                        style={{ width: "100%",  }}
                    />
                </SwiperSlide>
            ))}

        </Swiper>
    )
}

export default SwipeComponent


{/* <SwiperSlide >
<img
    src={cap1}
   // alt={`Image ${elm?.id}`}
    className='image-fluid'
    style={{width:"100%",height:"100%",objectFit:"contain"}}
/>
</SwiperSlide>
<SwiperSlide >
<img
    src={cap2}
    //alt={`Image ${elm?.id}`}
    className='image-fluid'
    style={{width:"100%",height:"100%",objectFit:"contain"}}
/>
</SwiperSlide>  <SwiperSlide >
<img
    src={cap3}
  //  alt={`Image ${elm?.id}`}
  className='image-fluid'
  style={{width:"100%",height:"100%",objectFit:"contain"}}
/>
</SwiperSlide> 
<SwiperSlide >
<img
    src={cap4}
  //  alt={`Image ${elm?.id}`}
  className='image-fluid'
  style={{width:"100%",height:"100%",objectFit:"contain"}}
/>
</SwiperSlide> */}