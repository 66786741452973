import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "./carouselStyles.css";
import slide1 from '../../../../assets/ch_1.png'
import slide2 from '../../../../assets/ch_2.png'
import slide3 from '../../../../assets/ch_3.png'

const ChallengeSlide = () => {
    return (
        <Swiper  className="mySwiper swiperParent swiper-slides" initialSlide={0}  >
            <SwiperSlide className='swiper-slides'>
                <img src={slide1} className="image-fluid" />
            </SwiperSlide>
            <SwiperSlide  className='swiper-slides'>
                <img src={slide2} className="image-fluid" />
            </SwiperSlide >
            <SwiperSlide  className='swiper-slides'> 
                <img src={slide3} className="image-fluid" />
            </SwiperSlide>
        </Swiper>
    )
}

export default ChallengeSlide