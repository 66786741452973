import React from 'react';
import { MdMyLocation } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLoggedUser } from '../functions/helper';
import { customRequest } from '../functions/request';
import { getUserDetails } from '../functions/services';
import useAlert from '../hooks/useAlert';
import { setAddViewMoods, setAllLanguages, setAllMoods, setCurrentLocation, setRemoveViewMoods, setSelectedLanguageId, setShowAlertPopup } from '../redux/dataSlice';
import SpinnerOverlay from '../components/OverlayLoader';
import MoodBadge from './moodBadge';

export default function LanguageAndMoodModal() {
    let user = getLoggedUser()
    const navigate = useNavigate();
    const { showAlertPopup } = useAlert()
    let dispatch = useDispatch();
    let allMoods = useSelector((state) => state.data.allMoods);
    let viewMoods = useSelector((state) => state.data.viewMoods);
    let selectedLanaguageId = useSelector((state) => state.data.selectedLanguageId);
    let allLanguages = useSelector((state) => state.data.allLanguages);
    const [languageBandges, setLanguageBadges] = React.useState(null);
    const [modalMoodsBadges, setModalMoodsBadges] = React.useState(null);
    const currentLocation = useSelector(state => state.data.currentLocation);
    const [isUpdating, setIsUpdating] = React.useState(false);


    const getMoods = (fetch = false) => {
        if (allMoods.length === 0 || fetch) {
            let langid = "";
            if (user && user.user_detail && user.user_detail.selected_language_id) {
                langid = user.user_detail.selected_language_id
            }
            if (user && user.user_detail) {
                customRequest('post/moods', { language_id: langid }).then((res) => {
                    dispatch(setAllMoods(res.moods));

                    let lmoods = user.user_detail.view_moods;
                    if (!lmoods || lmoods.length === 0) {
                        res.moods?.forEach(mood => {
                            dispatch(setAddViewMoods(mood.id))
                        });
                        let badges = res.moods?.map((mood) => {
                            return (
                                <MoodBadge key={mood.id}
                                    mood={mood}
                                    className={viewMoods.length == allMoods.length || viewMoods.includes(mood.id) ? "btn btn-light-modal-active" : "btn btn-light-modal"}
                                    onSelectMood={() => handleSelectMood(mood.id, "add")}
                                    onRemove={() => handleSelectMood(mood.id, "remove")}
                                />

                            )
                        })
                        setModalMoodsBadges(badges)
                    } else {
                        lmoods?.forEach(moodid => {
                            dispatch(setAddViewMoods(moodid));
                        })

                        let badges = res.moods?.map((mood) => {
                            return (
                                <MoodBadge key={mood.id}
                                mood={mood}
                                className={viewMoods.length == allMoods.length || viewMoods.includes(mood.id) ? "btn btn-light-modal-active" : "btn btn-light-modal"}
                                onSelectMood={() => handleSelectMood(mood.id, "add")}
                                onRemove={() => handleSelectMood(mood.id, "remove")}
                            />                            )
                        })
                        setModalMoodsBadges(badges)
                    }

                })

            }
        } else {
            let badges = allMoods.map((mood) => {
                return (
                    <MoodBadge key={mood.id}
                    mood={mood}
                    className={viewMoods.length == allMoods.length || viewMoods.includes(mood.id) ? "btn btn-light-modal-active" : "btn btn-light-modal"}
                    onSelectMood={() => handleSelectMood(mood.id, "add")}
                    onRemove={() => handleSelectMood(mood.id, "remove")}
                />                )
            })
            setModalMoodsBadges(badges);
        }
    }

    const handleSelectMood = (moodId, actionType) => {
        let viewMoodsToUpdate;
        if (actionType === "add") {
            viewMoodsToUpdate = [...viewMoods, moodId];
            dispatch(setAddViewMoods(moodId));
        } else {
            viewMoodsToUpdate = viewMoods.filter(moodid => moodid != moodId)
            dispatch(setRemoveViewMoods(moodId));
        }

        customRequest('user/update-view-moods', { moods: viewMoodsToUpdate }).then((res) => {
            let user = JSON.parse(localStorage.getItem('user'));
            user.user_detail.view_moods = res.moods;
            localStorage.setItem('user', JSON.stringify(user));
        });
    }

    const handleSelectLanguageId = (langaugeId) => {
        customRequest('user/update-selected-language', { language_id: langaugeId });
        dispatch(setSelectedLanguageId(langaugeId));
        let user = JSON.parse(localStorage.getItem('user'));
        user.user_detail.selected_language_id = langaugeId;
        localStorage.setItem('user', JSON.stringify(user));
    }


    const getLanguages = () => {
        if (allLanguages.length > 0) {
            setLanguageBadges(
                allLanguages.map((lang) => {
                    return (
                        <button type="button" key={lang.id} className={selectedLanaguageId === lang.id ? "btn btn-light-modal-active" : "btn btn-light-modal"} onClick={() => { handleSelectLanguageId(lang.id); }}>{lang.language_name}</button>
                    )
                })
            )
        } else {
            customRequest('post/languages').then((res) => {
                dispatch(setAllLanguages(res.languages));
                setLanguageBadges(
                    res.languages.map((lang) => {
                        return (
                            <button type="button" key={lang.id} className={selectedLanaguageId === lang.id ? "btn btn-light-modal-active" : "btn btn-light-modal"} onClick={() => { handleSelectLanguageId(lang.id); }}>{lang.language_name}</button>
                        )
                    })
                )
            })
        }
    }

    const refreshUserLocation = async (place) => {

        try {
            const res = await getUserDetails(user.id)
            //  console.log("get user details=====>", res)
            if (res.user !== null && res.user !== undefined) {
                user.user_detail.other_cities = res.user.user_detail.other_cities;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('location_coords', JSON.stringify({ lat: res?.location?.latitude, lng: res?.location?.longitude }))
                dispatch(setCurrentLocation(place))
                localStorage.setItem('location', place);

            }
        } catch (error) {

        }

    }

    const updateLocation = async (data) => {
        setIsUpdating(true)
        try {
            const res = await customRequest('user/updatelocation', { location: data })
            if (res.status === 'success') {
                setTimeout(async () => {
                    await refreshUserLocation(data)
                    setIsUpdating(false)

                }, 1000);
            }
        } catch (error) {
            setIsUpdating(false)
            showAlertPopup("Something went wrong, please try again later.")
        }

    }

    const handleCurrentLocation = async (city) => {
        if (city === "Everywhere") {
            dispatch(setCurrentLocation(city));
            localStorage.setItem('location', city);
            return;
        }
        if (city === "Nearme") {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const { latitude, longitude } = position.coords;
                    localStorage.setItem('location_coords', JSON.stringify({ lat: latitude, lng: longitude }));
                    dispatch(setCurrentLocation(city));
                    localStorage.setItem('location', city);
                });
            } else {
                dispatch(
                    setShowAlertPopup({
                        show: true,
                        title: "Alert",
                        message: "Geolocation is not supported by this browser.",
                    })
                );
            }
        } else {
            await updateLocation(city);
        }
    };


    const handleAddNewLocation = () => {
        document.body.classList.remove('modal-open');
        const backdropElements = document.querySelectorAll('.modal-backdrop');
        document.body.style.overflow = '';
        backdropElements.forEach(element => element.remove());
        if (!localStorage.getItem('token')) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: "Alert",
                    message: "Login to add new location.",
                    showLoginBtn: true
                })
            )
            return;
        }
        navigate('/locationSearchScreen', { state: { type: "search" } })
    }

    const handleAutoLanguageSelection = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user?.user_detail?.selected_language_id) {
            dispatch(setSelectedLanguageId(user?.user_detail?.selected_language_id));
        }
    }

    React.useEffect(() => {
        handleAutoLanguageSelection();
    }, [])

    React.useEffect(() => {
        getMoods(true);
        getLanguages();

    }, [selectedLanaguageId])

    React.useEffect(() => {

        getMoods();

    }, [viewMoods])

    return (
        <div
            className="modal windowmodal fade"
            id="languageModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-3 border-bottom">
                        <h5 className="modal-title">Personalize Your Feed</h5>
                        <div
                            className="modal-close ml-auto"
                            data-bs-dismiss="modal"
                            aria-label="Close">
                            <img alt='localbol' src="assets/images/Arrow-Left.svg" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex flex-column bd-highlight">
                            <div className="py-1 bd-highlight">
                                <h6>Location</h6>
                            </div>
                            <div className="py-1 bd-highlight">
                                {
                                    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.user_detail?.other_cities
                                        ?
                                        JSON.parse(localStorage.getItem('user'))?.user_detail?.other_cities.split(',')?.filter(city => city !== 'null' && city !== 'undefined' && city !== "")?.map(city => {
                                            return (
                                                <button key={city} type="button" className={currentLocation === city ? "btn btn-light-modal-active" : "btn btn-light-modal"} onClick={() => handleCurrentLocation(city)}>{city}</button>
                                            )
                                        })
                                        :
                                        null
                                }
                            </div>
                            <div className="py-1 bd-highlight">
                                <button className={currentLocation === "Nearme" ? "btn btn-light-modal-active" : "btn btn-light-modal"} onClick={() => handleCurrentLocation('Nearme')}  >
                                    {/* <i className="fas fa-location fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize", marginRight: 5 }}></i> */}
                                    <MdMyLocation size={18} style={{ color: '#605e5c', marginRight: 4 }} />
                                    Near Me
                                </button>
                                <button className={currentLocation === "Everywhere" ? "btn btn-light-modal-active" : "btn btn-light-modal"} onClick={() => handleCurrentLocation('Everywhere')}>
                                    <i className="fas fa-globe fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize", marginRight: 5 }}></i>
                                    Everywhere
                                </button>
                                <button className="btn btn-light-modal" onClick={() => { handleAddNewLocation() }}>
                                    <i className="fas fa-plus fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize", marginRight: 5 }}></i>
                                    Add Location
                                </button>

                            </div>
                            <div className="py-1 bd-highlight">
                                <h6>Show posts only in..</h6>
                            </div>
                            <div className="py-1 bd-highlight">
                                {
                                    languageBandges
                                }
                            </div>
                            {/* <div className="py-1 bd-highlight border-bottom mb-2">
                                <h6 className="mb-2"><small>More languages..</small></h6>
                            </div> */}
                            <div className="py-1 bd-highlight">
                                <h6>What moods would like to see ?</h6>
                            </div>
                            <div className="py-1 bd-highlight">
                                {/* <button type="button" className={selectedMoodIds.length == allMoodIds.length ? "btn btn-light-modal-active" : "btn btn-light-modal"} onClick={() => { setIsMoodSelectionChanged(!isMoodSelectionChanged); setSelectedMoodIds(allMoodIds) }}>All</button> */}
                                {modalMoodsBadges}
                            </div>
                            {/* <div className="py-1 bd-highlight">
                                <h6><small>More Moods..</small></h6>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {isUpdating && <SpinnerOverlay />}
        </div>
    )
}