import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getLoggedUser } from '../functions/helper';
import { setShowAlertPopup } from '../redux/dataSlice';
import { MdShare } from 'react-icons/md';
import { Colors } from '../constants/colors';
import { RWebShare } from 'react-web-share';
import { contestShareLink } from '../functions/link';

export default function ContestCard(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const handleGotoContest = () => {
        if (getLoggedUser()) {
            navigate('/contest', { state: { contest_id: props.contest.id } })
        } else {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to see contest.",
                        showLoginBtn: true
                    }
                )
            );
        }
    }


    const transparentBtn = {
        backdropFilter: 'blur(2px)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "0.625rem",
        borderRadius: "20px",
    }



    return (
        <div className="px-2 bd-highlight " style={{ maxWidth: "600px" }}>
            <div className="Big-card big-card-bg-two">
                <div className="Big-card-body" style={{ position: "relative", overflow: "hidden", zIndex: 1 }}>

                    <div onClick={handleGotoContest} style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, borderRadius: 16, overflow: "hidden", zIndex: -1, height: "100%" }}>
                        <img alt='localbol'
                            src={props.contest.thumbnail_image}
                            className="img-fluid"
                            style={{ zIndex: 1, height: "100%", width: "100%" }}
                            loading="lazy"
                        />
                    </div>
                    <RWebShare
                        data={{
                            //text: props?.post?.contents[0]?.text,
                            url: `${contestShareLink}/${props?.contest.id}`,
                            title: "Share this Sadhana",
                        }}
                    >
                        <div
                            className="px-2 mt-4 btn text-center "
                            style={{
                                color: Colors.primary,
                                position: 'absolute', right: 10, top: -10,
                                ...transparentBtn,
                                fontSize:'0.8rem'
                            }}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <MdShare />
                                <p className="ml-1 m-0">Share</p>
                            </div>
                        </div>
                    </RWebShare>
                    <div style={{ position: 'absolute', right: 10, bottom: 10, display: 'flex', flexDirection: 'column' }}>
                        {
                            props?.contest?.hashtag
                                ?
                                props.contest?.hashtag?.toString()?.split(",")?.map((tag, index) => {
                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            className="btn  text-white" style={{
                                                marginRight: "auto",
                                                borderColor: Colors.white,
                                                ...transparentBtn
                                            }}>
                                            #{tag}
                                        </button>

                                    )
                                })
                                :
                                null
                        }


                    </div>

                </div>
            </div >



        </div >
        // <div className="px-2 bd-highlight" style={{maxWidth:"600px"}}>
        //     <a onClick={handleGotoContest} className="Big-card big-card-bg-two">
        //         <div className="Big-card-body" style={{ position: "relative", overflow: "hidden", zIndex: 1 }}>

        //             <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, borderRadius: 16, overflow: "hidden", zIndex: -1, height: "100%" }}>
        //                 <img alt='localbol'
        //                     src={props.contest.thumbnail_image}
        //                     className="img-fluid"
        //                     style={{ zIndex: 1,height: "100%", width: "100%" }}
        //                     loading="lazy"
        //                 />
        //             </div>

        //             <div style={{ zIndex: 1, borderRadius: 20, padding: 8, display: "flex", flexDirection: "column",position:'absolute',bottom:0 }}>
        //                 {
        //                     props?.contest?.hashtag
        //                         ?
        //                         props?.contest?.hashtag?.toString()?.split(",")?.map((tag,index) => {
        //                             return (
        //                                 <button
        //                                 key={index}
        //                                     type="button"
        //                                     className="btn btn-light-small" style={{ marginRight: "auto" }}>
        //                                     #{tag}
        //                                 </button>

        //                             )
        //                         })
        //                         :
        //                         null
        //                 }
        //                 {/* <div style={{ color: "#6945B4", display: "inline", marginRight: "auto" }} className="btn btn-light-small">
        //                     {
        //                         props.contest.details && props.contest.details[0]
        //                             ?
        //                             props.contest.details[0].title
        //                             :
        //                             null
        //                     }
        //                 </div> */}
        //                 {/* <h5 style={{fontSize:14,fontWeight:"500",color:"white"}}>Start On : {new Date(props.contest.start_date).toLocaleString()}</h5> */}
        //                 {/* <h5 style={{fontSize:14,fontWeight:"500",color:"#6945B4"}}>End On : {new Date(props.contest.end_date).toLocaleString()}</h5> */}
        //                 {/* <h5 style={{fontSize:14,fontWeight:"500",color:"white"}}>Result On : {new Date(props.contest.result_date).toLocaleString()}</h5> */}
        //                 {/* <h6 className="mt-3">T&C Applicable</h6> */}
        //             </div>


        //         </div>
        //     </a>
        // </div>
    )
}