import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getLoggedUser } from '../../../../functions/helper'
import { customRequest, customRequestGET, getApiService } from '../../../../functions/request'
import { setShowAlertPopup } from '../../../../redux/dataSlice'

const slidesArray = []
const useToday = () => {
    const [showChallenge, setShowChallenge] = useState(true)
    const [showCommunity, setShowCommunity] = useState(true)
    const [collection, setCollection] = useState([])
    const [selectedImage, setSelectedImage] = useState({})
    const [slideImages, setSlideImages] = useState([])
    const [activeSlideData, setActiveSlideData] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleBuy = () => {
        window.open(activeSlideData?.button_url, '_blank');
    }

    const storeDataInCache = (category_id, data) => {
        const isAlreadyAdded = slidesArray.some(elm => elm?.category_id === category_id)
        if (!isAlreadyAdded) slidesArray.push({ category_id, data })
    }

    useEffect(() => {
        customRequestGET("today/allCategories")
            .then(data => {
                if (Array.isArray(data?.today_categories)) {
                    const todayData = data?.today_categories?.find((elm, index) => {
                        if (moment().format("dddd").toLowerCase() === elm?.default_day?.toLowerCase()) {
                            return index
                        }
                    })
                    if (data?.today_categories) setCollection(data?.today_categories)


                    if (todayData?.id) {//select default image for current day
                        setSelectedImage(todayData)
                    } else {
                        setSelectedImage(data?.today_categories[0])
                    }
                }
            })
            .catch(e => console.log(e))


    }, [])

    useEffect(() => {
        const reqBody = {
            "category_id": selectedImage?.id
        }
        const alreadyAvilableInCache = slidesArray.find(elm => elm?.category_id === selectedImage?.id)
      //  console.log("alreadyAvilableInCache", alreadyAvilableInCache)
        if (alreadyAvilableInCache) {
            setSlideImages(alreadyAvilableInCache?.data)
            setActiveSlideData(alreadyAvilableInCache?.data[0] || {})
            return;
        }
        customRequest("today/allCategoryImages", reqBody)
            .then(data => {
                console.log("effect called")
                if (Array.isArray(data?.today_category_data)) {
                    setSlideImages(data?.today_category_data)
                    setActiveSlideData(data?.today_category_data[0] || {})
                    storeDataInCache(reqBody.category_id, data?.today_category_data)
                    // slidesArray.push({ category_id: reqBody.category_id, data: data?.today_category_data })
                }
            })
            .catch(e => console.log(e))

    }, [selectedImage])

    const handleAllChalenges = () => {
        navigate('/contestlist')
    }

    const handleCreatePost = () => {
        if (!getLoggedUser()) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to create new post",
                        showLoginBtn: true
                    }
                )
            )
            return;
        }
        navigate('/newpost')
    }

    const handleMyChallenges = () => {
        navigate('/contestlist', { state: { redirectToMyChallenge: true } })
    }
    return { showCommunity, setShowCommunity, showChallenge, setShowChallenge, handleAllChalenges, handleMyChallenges, slideImages, collection, setSelectedImage, selectedImage, activeSlideData, setActiveSlideData, handleBuy, handleCreatePost }
}

export default useToday;