import {configureStore} from "@reduxjs/toolkit";
import dataSlice from "./dataSlice";
import registrationSlice from "./registrationSlice";
import alertSlice from "./alertSlice";


export default configureStore(
    {
        reducer: {
            data: dataSlice,
            registrationData: registrationSlice,
            alert:alertSlice
        }
    }
)