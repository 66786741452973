import React, { useEffect, useState } from 'react'
import { Colors } from '../../../../constants/colors'
import { getLoggedUser } from '../../../../functions/helper'

const Greet = () => {
    const user = getLoggedUser()
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour >= 5 && currentHour < 12) {
            setGreeting('Good morning');
        } else if (currentHour >= 12 && currentHour < 17) {
            setGreeting('Good afternoon');
        } else {
            setGreeting('Good evening');
        }
    }, []);

    return (
        <div style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10, color: Colors.primary,  alignItems: "flex-start" }}>
            <div style={{ display: "flex", alignItems: "center",}}>
                <p className="m-0" style={{ textTransform: "capitalize", fontSize: "1rem", fontWeight: "500" }}>{greeting}</p>
            </div>
            <p className="m-0" style={{ textTransform: "capitalize", fontSize: "1rem", fontWeight: "500" }}>{user?.full_name || "Guest"}</p>

        </div>
    )
}

export default Greet