import React from 'react';
import { MdArrowBack, MdImage, MdInsertPhoto } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defaultUserImageLink, imageRootlink } from '../functions/link';
import { customMultipartRequest } from '../functions/request';
import { setShowAlertPopup } from '../redux/dataSlice';

export default function EditProfileScreen() {
    const navigate = useNavigate();
    const [img, setImg] = React.useState(null);
    const [fullName, setFullName] = React.useState(null);
    const [phoneNumber, setPhoneNumber] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [about, setAbout] = React.useState(null);
    const [bio, setBio] = React.useState(null);
    const [username, setUsername] = React.useState(null);
    const dispatch = useDispatch();
    const [prevImg, setPrevImg] = React.useState(null);


    const handleInput = (e) => {
        if (e.target.name === "profilepic") {
            setImg(e.target.files[0])
            let reader = new FileReader();
            reader.onload = () => {
                document.getElementById('displayimg').src = reader.result
            }
            reader.readAsDataURL(e.target.files[0]);
        } else if (e.target.name === "fullname") {
            setFullName(e.target.value)
        } else if (e.target.name === "phone") {
            setPhoneNumber(e.target.value)
        } else if (e.target.name === "email") {
            setEmail(e.target.value)
        } else if (e.target.name === "bio") {
            setBio(e.target.value);
        } else if (e.target.name === "about") {
            setAbout(e.target.value)
        }
    }

    const handleUpdate = () => {
        let formdata = new FormData();
        formdata.append('profilepic', img);
        formdata.append('fullname', fullName);
        formdata.append('phone', phoneNumber);
        formdata.append('email', email);
        formdata.append('about', about);
        formdata.append('bio', bio)


        customMultipartRequest('user/updateprofile', formdata).then((res) => {
            if (res.status === "success") {
                localStorage.setItem('user', JSON.stringify(res.user));
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: "Profile updated successfully.",
                        }
                    )
                )
                navigate(-1);
            }
        })
    }

    const getLocalUser = () => {
        let user = JSON.parse(localStorage.getItem('user'))
        setFullName(user.full_name);
        setPhoneNumber(user.phone);
        setEmail(user?.email === "null" ? "" : user?.email);
        setAbout(user?.user_detail?.about);
        setBio(user?.user_detail?.bio ?? "I am a LocalBol User");
        setUsername(user?.username);
        setPrevImg(user?.user_detail?.profile_pic);
    }

    React.useEffect(() => {
        getLocalUser()
    }, [])


    return (
        <div style={{ height: "100%", width: "100%", position: "relative" }} >

            <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px" }}>
                <MdArrowBack size={24} onClick={() => { navigate(-1) }} cursor="pointer" />
                <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Edit Profile</h4>
            </div>
            <div style={{ padding: "6px 16px", marginTop: 16, display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
                {
                    img === null && prevImg == null
                        ?
                        <label htmlFor='selectimage' style={{ position: "relative", height: 120, width: 120, background: "rgba(0,0,0,0.1)", borderRadius: 120, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                            <img src={defaultUserImageLink} alt="Localbol User" style={{ height: "90%", width: "90%", borderRadius: "50%" }} />
                            <div style={{ position: "absolute", bottom: 0, right: 0, left: 0, margin: "0 8px", backgroundColor: "white", padding: "4px 8px", borderRadius: 60, boxShadow: "0 4px 4px rgba(0,0,0,0.1)", alignItems: "center", justifyContent: "center" }}>
                                {/* <MdInsertPhoto size={26} /> */}
                                <div style={{ fontSize: 12, textAlign: "center" }}>Change Profile</div>
                            </div>
                        </label>
                        :
                        <div style={{ position: "relative" }}>
                            <img id="displayimg" src={prevImg == null ? "" : imageRootlink + "/profilepic/" + prevImg} style={{ height: 120, width: 120, background: "rgba(0,0,0,0.1)", borderRadius: 120, display: "block", objectFit: "cover" }} />
                            {/* <label htmlFor='selectimage' style={{ position: "absolute", bottom: -10, right: 0, height: 40, width: 40, borderRadius: 60, backgroundColor: "white", boxShadow: "0 0 4px rgba(0,0,0,0.1)", padding: 8, border: "1px solid gray", zIndex: 10 }}>
                                <MdImage size={24} style={{ marginBottom: 6 }} />
                            </label> */}
                            <label htmlFor='selectimage' style={{ position: "absolute", bottom: 0, right: 0, left: 0, margin: "0 8px", backgroundColor: "white", padding: "4px 8px", borderRadius: 60, boxShadow: "0 4px 4px rgba(0,0,0,0.1)", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                {/* <MdInsertPhoto size={26} /> */}
                                <div style={{ fontSize: 12, textAlign: "center" }}>Change Profile</div>
                            </label>
                        </div>
                }
                <input type="file" style={{ display: "none" }} id="selectimage" name='profilepic' onChange={handleInput} />
            </div>
            <div style={{ padding: "6px 16px", marginTop: 6 }}>
                <label style={{ fontSize: 15, fontFamily: "Open Sans", display: "block" }}>Full Name</label>
                <input style={{ flex: 1, border: "1px solid #6945B4", borderRadius: 5, padding: "6px 10px", width: "100%" }} value={fullName} name="fullname" placeholder="Full Name" onChange={handleInput} />
            </div>

            <div style={{ padding: "6px 16px", marginTop: 6 }}>
                <label style={{ fontSize: 15, fontFamily: "Open Sans", display: "block" }}>Username</label>
                <input readOnly style={{ flex: 1, border: "1px solid #6945B4", borderRadius: 5, padding: "6px 10px", width: "100%", backgroundColor: "rgba(0,0,0,0.1)" }} value={username} name="fullname" placeholder="Full Name" onChange={handleInput} />
            </div>
            <div style={{ padding: "6px 16px" }}>
                <label style={{ fontSize: 15, fontFamily: "Open Sans", display: "block" }}>Phone Number</label>
                <input style={{ flex: 1, border: "1px solid #6945B4", borderRadius: 5, padding: "6px 10px", width: "100%" }} value={phoneNumber} maxLength={10} name="phone" placeholder="xxxxx99999" onChange={handleInput} />
            </div>
            <div style={{ padding: "6px 16px" }}>
                <label style={{ fontSize: 15, fontFamily: "Open Sans", display: "block" }}>Email</label>
                <input style={{ flex: 1, border: "1px solid #6945B4", borderRadius: 5, padding: "6px 10px", width: "100%" }} value={email} name="email" placeholder="xyz@example.com" onChange={handleInput} />
            </div>
            <div style={{ padding: "6px 16px" }}>
                <label style={{ fontSize: 15, fontFamily: "Open Sans", display: "block" }}>Bio</label>
                <input style={{ flex: 1, border: "1px solid #6945B4", borderRadius: 5, padding: "6px 10px", width: "100%" }} value={bio} name="bio" placeholder="I am a LocalBol User" onChange={handleInput} />
            </div>
            <div style={{ padding: "6px 16px" }}>
                <label style={{ fontSize: 15, fontFamily: "Open Sans", display: "block" }}>About Me</label>
                <textarea style={{ flex: 1, border: "1px solid #6945B4", borderRadius: 5, padding: "6px 10px", width: "100%" }} value={about} name="about" placeholder="write something..." onChange={handleInput} ></textarea>
            </div>
            <div style={{ height: 90 }}></div>
            <div style={{ position: "fixed", bottom: 0, left: 0, right: 0, padding: "12px 12px", backgroundColor: "#6945B4", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleUpdate}>
                <span style={{ fontFamily: "Open Sans", fontSize: 16, fontWeight: "600", display: "block", color: "white" }}>UPDATE</span>
            </div>
        </div>
    )
}