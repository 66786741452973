import React from 'react'
import { MdChevronLeft } from 'react-icons/md'
import CustomButton from '../../../components/CustomButton'
import { Colors } from '../../../constants/colors'
import SwipeComponent from './slides'
import Pattern from '../../../assets/pattern.svg'
import useToday from './hook/useToday'
import CommunitySlide from './carousel/communitySlides'
import ChallengeSlide from './carousel/challengeSlides'

const Today = ({ onShowCommunity }) => {
  const { showChallenge, showCommunity, handleAllChalenges, handleMyChallenges, setShowChallenge, setSelectedImage, setShowCommunity, selectedImage, slideImages, collection, setActiveSlideData, handleBuy, handleCreatePost } = useToday()



  return (
    <div className='container-fluid main_warp pb-4' style={{ backgroundColor: Colors.white, height: "100vh", width: "100vw", overflow: "scroll", }}>

      <div className='mt-3 pb-4 shadow' style={{ background: 'linear-gradient(180deg, rgba(105, 69, 174, 0.80) 0%, rgba(217, 217, 217, 0) 25%)',  }}>
        <div className='d-flex pt-1 px-3 today-product' style={{
          overflowX: 'scroll', background: `url(${Pattern})`, backgroundSize: 'cover', width: '100%', backgroundRepeat: 'no-repeat', height: "6.5rem", alignItems: "center",
          WebkitOverflowScrolling: 'touch',
          justifyContent: "space-between"
        }}>
          {
            collection?.map(elm => (
              <img  loading='lazy'
              onClick={() => setSelectedImage(elm)} src={elm?.category_image} key={elm.id} className='image-fluid mx-1'
                style={{
                  height: "4.5rem",
                  width: "4.5rem",
                  borderRadius: "50%", // half of the height and width
                  border: ` ${elm?.id === selectedImage?.id ? '4px dashed ' + Colors.red : 'none'}`,
                  // boxShadow: elm?.id === selectedImage?.id
                  //   ? `0 0 0px 0px ${Colors.red}, 0 0 8px 4px ${Colors.red}`
                  //   : 'none',
                  marginTop: 2
                }} />
            ))
          }

        </div>
        <div className='d-flex flex-column text-center m-0 ' style={{  }}>
          <div>
            <p className='m-0' style={{ fontWeight: "400", fontSize: "0.8rem" }}>{selectedImage?.caption}</p>
            <p className='m-0' style={{
              fontSize: "1.5rem",
              fontFamily: 'Nova Flat',
              fontWeight: '600',
              color: Colors.primary
            }}> {selectedImage?.caption} {selectedImage?.headline}</p>
          </div>
          <div className='my-4' style={{ width: "100%"}}>
            <SwipeComponent slides={slideImages} setActiveSlideData={setActiveSlideData} />
          </div>
          <div>
            <p className='text-center m-0 ' style={{
              fontSize: "1.3rem",
              fontFamily: 'Nova Flat',
              fontWeight: '400',
              color: Colors.primary,
            }}>  {selectedImage?.category_name}</p>
            <div className='text-center pb-2'>  <CustomButton  onClick={handleBuy} label={"Buy It Now"} customStyle={{ width: "40%" }} /></div>
          </div>
        </div>
      </div>

      <div className='mt-3 p-3 shadow' style={{ backgroundColor: Colors.white }}>
        <div onClick={() => setShowChallenge(!showChallenge)} className="p-2" >
          <div className="p-2 d-flex justify-content-between align-items-center fs-5 font-weight-bold">
            <p className="m-0" style={{ color: Colors.primary }}>Challenges</p>
            <MdChevronLeft size={25} style={{ transform: 'rotate(-90deg)' }} />
          </div>
        </div>
        {showChallenge &&
          <div className='p-1' >
            <p className='mt-2'>Start Your Fittest Journey with Fittest Club</p>
            {/* <div className='d-flex align-items-center'>
              <img src={Sharing} alt="share_icon" />
              <p className='ml-2 m-0' style={{ fontSize: "0.9rem" }}> <b>Join</b>,<b> practice</b> , and <b>connect</b> with others.  </p>
            </div>

            <div className='d-flex align-items-center mt-2'>
              <img src={Fire} alt="fire_icon" />
              <p className='ml-2 m-0' style={{ fontSize: "0.9rem" }}>Walk Towards A Healthier You</p>

            </div>
            <div className='d-flex align-items-center mt-2'>
              <img src={Signup} alt="signup_icon" />
              <p className='ml-2 m-0' style={{ fontSize: "0.9rem" }}> <b>Sign up now</b> and begin your transformative journey! </p>

            </div> */}
            <ChallengeSlide />

            <div className='p-2 d-flex justify-content-between align-items-center'>
              <CustomButton onClick={handleAllChalenges} label={"All Challenges"} className={"btn-block mr-2 text-white"} color={Colors.secondary} />
              <CustomButton onClick={handleMyChallenges} label={"My Challenges"} className={"btn-block"} color={Colors.orange500} />
            </div>
            
          </div>
        }

      </div>

      <div className='mt-3 p-3 shadow' style={{ backgroundColor: Colors.white }}>
        <div onClick={() => setShowCommunity(!showCommunity)} className="p-2" >
          <div className="p-2 d-flex justify-content-between align-items-center fs-5 font-weight-bold">
            <p className="m-0" style={{ color: Colors.primary }}>Community</p>
            <MdChevronLeft size={25} style={{ transform: 'rotate(-90deg)' }} />
          </div>
        </div>
        {showCommunity &&
          <div className='p-1' >
            <p className='mt-2 '>Discover the Fittest Club Community for</p>
            {/* <div className='d-flex align-items-center mt-2'>
              <img src={Poster} alt="fire_icon" />
              <p className='ml-2 m-0' style={{ fontSize: "0.9rem" }} ><b> Poster Maker: </b>Design stunning business or personal branding posters effortlessly for free with our easy Poster Maker tool.   </p>

            </div>  <div className='d-flex align-items-center mt-2'>
              <img src={Pray} alt="fire_icon" />
              <p className='ml-2 m-0' style={{ fontSize: "0.9rem" }}><b>Wellness Content:</b> Dive into a wealth of helpful and engaging content covering sports, fitness, health, nutrition, sleep, mindfulness, appearance, and more. </p>

            </div>  <div className='d-flex align-items-center mt-2'>
              <img src={Earth} alt="fire_icon" />
              <p className='ml-2 m-0' style={{ fontSize: "0.9rem" }}><b>Hyperlocal Community:</b>Forge connections with individuals in your area who share your language and interests, fostering a vibrant local network. </p>

            </div> */}

            <CommunitySlide />
            <div className='p-2 d-flex justify-content-between align-items-center'>
              <CustomButton onClick={onShowCommunity} label={"Visit Community"} className={"btn-block mr-2 text-white"} color={Colors.orange500} />
              <CustomButton onClick={handleCreatePost} label={"Create Post"} className={"btn-block"} color={Colors.secondary} />
            </div>
          </div>
        }

      </div>

    </div>
  )
}

export default Today