import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostCard } from '../../components/postCard';
import { customRequest } from '../../functions/request';
import { setShowAlertPopup } from '../../redux/dataSlice';
import moment from "moment";
import CustomModal from '../../components/CustomModal';
import DayCard from '../../components/DayCard';
import { getLoggedUser } from '../../functions/helper';
import HorizontalContestTab from '../../components/horizontalContestTab';
import { MdArrowBack } from 'react-icons/md';
import OverlayLoader from '../../components/OverlayLoader';
import ChallengeDetails from './challengeDetails';

const tabsData = [
  'Challenge Details',
  'Challenge Schedule',
  'Entries',
  'Winners',
  // Add more tabs as needed
];

export default function ContestScreen() {
  let navigate = useNavigate();
  let location = useLocation();
  let [contest, setContest] = React.useState(null);
  const [posts, setPosts] = React.useState(null);
  const [winnerPosts, setWinnerPosts] = React.useState(null);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState('Challenge Details');
  const [contestDays, setContestDays] = React.useState([]);
  const [contestAlert, setContestAlert] = React.useState({ visible: false, message: '' });
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [isParticipated, setisParticipated] = React.useState(false)
  const [showCalender, setShowCalender] = React.useState(false)
  const [participation_date, setParticipation_date] = React.useState(new Date());




  const getContestPosts = () => {
    customRequest('post/contest-posts', { contest_id: location?.state?.contest_id }).then((res) => {
      //   console.log('post',res)
      setPosts(
        res?.posts?.map(post => {
          return (
            <PostCard key={post.id} post={post} />
          )
        })
      )
    }).catch((e) => {})
  }
  const getContest = () => {
    const user = getLoggedUser()
    customRequest('contest/singlecontest', { contest_id: location?.state?.contest_id, user_id: user?.id }).then((res) => {
      if (res.participate_date ) {
        setisParticipated(true)
        //user already participated
        setSelectedTab(tabsData[1])
        let temp = []
        //adding date for each day of contest task
        res.contestDays?.forEach((element, index) => {
          const item = {
            ...element,
            participate_date: index === 0 ? moment(res.participate_date)?.format('L') : moment(res.participate_date)?.add(index, 'days').format('L')

          }
          temp.push(item)

        });
        setContestDays(temp)
      } else {
        setContestDays(res.contestDays ? res.contestDays : [])
      }
      setContest(res.contest);
      setStartDate(res.participate_date)
      setWinnerPosts(res.winnerPosts);
    }).catch((e) => {})
    getContestPosts();
  }

  const acceptChallenge = () => {
 //  console.log(contest.id)
    setLoading(true)
    // navigate('/contestlist',{state:{redirectToMyChallenge:true}})
    customRequest('contest/participate-contest', { contest_id: contest.id ,participation_date}).then(res => {
      //   console.log(res)
      setContestAlert({ visible: true, message: res.message })
      setLoading(false)

      // nav
    }).catch(e =>
      {console.log(e)

      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        )
      )}
      ).finally(() => setLoading(false))
  }

  const handleParticipate = () => {
    if (getLoggedUser()) {
      if (moment().isAfter(moment(contest.end_date))) {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Oops! this contest is closed.",
            }
          )
        )
      } else {


        if (contest.media_type === "external") {
          window.open(contest?.external_media_link, '_blank');
        } else {
          acceptChallenge()
          // navigate('/contestpost', { state: { source: "contest", contest_id: location.state.contest_id, media_type: contest.media_type } })
        }

      }
    } else {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Please login to participate.",
            showLoginBtn: true
          }
        )
      )
    }
  }

  React.useEffect(() => {
    getContest();
  }, [])

  const handleSelectTab = (tab) => {
    setSelectedTab(tab)
  }

  if (contest == null) {
    return <div></div>
  }

  return (
    <React.Fragment>
      {loading && <OverlayLoader/>}
      <div className="main_warp">
      <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
        <MdArrowBack size={24} onClick={() => { navigate('/home') }} cursor="pointer" />
        <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Challenges</h4>

        <a className="search-icons searchicons" style={{marginLeft:'auto'}} onClick={() => { navigate(-1) }}>
          <img src="assets/images/Arrow-Left.svg" />
        </a>
      </div>

        <div class="aboutheader">
          <img src={contest.banner_image} class="img-fluid" />
        </div>
        <HorizontalContestTab hideScheduleTab={contestDays[0]?.participate_date ? false : true} tabsData={tabsData} selectedTab={selectedTab} handleSelectTab={handleSelectTab} />
        <div class="container px-0">
          <div class="productdetail">
            <div class="productdetailview" style={{ padding: 0 }}>
              <div class="tab-content" id="myTabContent">
                {selectedTab === 'Challenge Details' &&  
                 <ChallengeDetails 
                 contest={contest} isParticipated={isParticipated} 
                 handleParticipate={handleParticipate} 
                 participateDate={participation_date}
                  setParticipation_date={setParticipation_date}
                  showCalender={showCalender}
                  setShowCalender={setShowCalender}
                   /> }
                {selectedTab === 'Challenge Schedule' && <div
                 style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row',justifyContent:'center' }}

                >
                  {!!contestDays?.length && Array.isArray(contestDays) && contestDays?.map((elm, index) => (
                    <DayCard key={index} details={elm} startDate={startDate} banner={contest?.banner_image} contestId={contest?.id} hashTag={contest?.hashtag}contestUnit={contest?.details[0]?.contest_unit_label} />
                  ))}
                </div>}
                {selectedTab === 'Entries' && <div>
                  {
                    posts
                  }
                </div>}

                {selectedTab === 'Winners' && <div>
                  {
                    winnerPosts?.length !== 0
                      ?
                      winnerPosts?.map((post, index) => {
                        return (
                          <PostCard key={index} post={post} />
                        )
                      })
                      :
                      <div style={{ height: 260, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: '0px' }}>
                        <img src={require("../..//assets/empty_post.png")} style={{ height: 160, width: 240, objectFit: "contain" }} />
                        <span>Contest result not decleared yet.</span>
                      </div>
                  }
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal windowmodal fade"
        id="Participate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        //tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header px-3">
              <div
                class="modal-close ml-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/images/Arrow-Left.svg" />
              </div>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-column bd-highlight text-center">
                <div class="p-2 bd-highlight">
                  <div class="modallogoimg">
                    <img src="assets/images/Appicon.png" class="img-fluid" />
                  </div>
                </div>
                <div class="p-2 bd-highlight text-center">
                  <h2>Install the app..</h2>
                  <p class="text-center">Install the app today &amp; get access to Amet minim mollit non<br />deserunt ullamco est sit aliqua dolor do amet sint. </p>
                </div>
                <div class="pb-2 bd-highlight">
                  <div class="playstore"><a href="#"><img src="assets/images/Playstore.png" class="img-fluid" /></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {contestAlert?.visible && <CustomModal message={contestAlert?.message}  hideModal={() => setContestAlert({ visible: false, message: '' })} />}

    </React.Fragment>
  )
}