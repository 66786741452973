import React from 'react';
import { MdArrowBack, MdLocationPin, MdClose } from "react-icons/md";
import Spinner from 'react-spinkit';
import { getLocationDetails } from '../../functions/helper';
import useLocationSearch from './hook/useLocationSearch';
import SpinnerOverlay from '../../components/OverlayLoader';
import { Colors } from '../../constants/colors';


export default function LocationSearchScreen() {
    const { result, navigateBack, deleteSavedLocation, handleOnInputSearch, handleSelectLocation, placePredictions, searchText, isLoading, searchBy, isPlacePredictionsLoading, savedLocations, isDeleting, setSearchBy, searchLocation, onInputPlacePrediction, isUpdating, setSearchText ,setIsLoading} = useLocationSearch()

    // const navigate = useNavigate();
    // const [searchString, setSearchString] = React.useState('');
    // const [result, setResults] = React.useState(null);
    // const [isLoading, setIsLoading] = React.useState(false);
    // const dispatch = useDispatch();
    // const location = useLocation();
    // const [searchBy, setSearchBy] = React.useState("pincode");
    // const [savedLocations, setSavedLocations] = React.useState(JSON.parse(localStorage.getItem('user'))?.user_detail?.other_cities ? JSON.parse(localStorage.getItem('user'))?.user_detail?.other_cities.split(',') : []);
    // const [timer, setTimer] = React.useState(null);
    // const [isUpdating, setIsUpdating] = React.useState(false);

    // const searchLocation = (search = "") => {
    //     setResults(null);
    //     setIsLoading(true)
    //     if (search === "current") {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(position => {
    //                 customRequestGETBare(`https://maps.googleapis.com/maps/api/geocode/json?address=${position.coords.latitude},${position.coords.longitude}&sensor=true&key=${googleMapApiKey}`).then((res) => {
    //                     // let location = res.results[0].address_components[4].long_name;
    //                     setIsLoading(false);
    //                     setResults(
    //                         res.results[0].address_components.map((place) => {
    //                             return (
    //                                 <div key={place} style={{ padding: "12px 12px", width: "100vw", display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.05)", cursor: 'pointer' }} onClick={() => { handleSelectLocation(place.long_name) }}>
    //                                     <MdLocationPin />
    //                                     <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}>{place.long_name}</span>
    //                                     <MdAddLocation style={{ marginLeft: "auto" }} />
    //                                 </div>
    //                             )
    //                         })
    //                     )
    //                     // dispatch(setCurrentLocation(location));
    //                     // localStorage.setItem('location', location);
    //                     return;
    //                 })
    //             });
    //         } else {
    //             alert("Geolocation is not supported by this browser.");
    //         }
    //     } else {
    //         if (searchBy === "pincode" && search.length !== 6) {
    //             dispatch(
    //                 setShowAlertPopup(
    //                     {
    //                         title: "Alert",
    //                         message: "Enter 6 digit pincode to search location.",
    //                         show: true,
    //                     }
    //                 )
    //             )
    //             return;
    //         }
    //         customRequestGETBare("https://maps.googleapis.com/maps/api/geocode/json?address=" + search + "&sensor=true&key=" + googleMapApiKey).then((res) => {
    //             setIsLoading(false);
    //             if (searchBy === "pincode") {
    //                 if (search.length !== 6 ) {
    //                     dispatch(
    //                         setShowAlertPopup(
    //                             {
    //                                 title: "Alert",
    //                                 message: "Enter 6 digit pincode to search location.",
    //                                 show: true,
    //                             }
    //                         )
    //                     )
    //                     return;
    //                 }
    //                 customRequestGETBare("https://maps.googleapis.com/maps/api/geocode/json?address=" + parseInt(search) + "&sensor=true&key=" + googleMapApiKey).then((res) => {
    //                     setIsLoading(false);
    //                   //  console.log(res)
    //                     if (res !== undefined && res.status !== "ZERO_RESULTS") {
        
    //                         const addressComponents = res?.results[0]?.address_components;
    //                         const city = addressComponents?.find((comp) =>
    //                             comp.types.includes('locality') || comp.types.includes('administrative_area_level_3')
    //                         )?.long_name;
    //                         const pinCode = addressComponents?.find((comp) =>
    //                             comp.types.includes('postal_code')
    //                         )?.long_name;
    //                         const state = addressComponents?.find((comp) =>
    //                             comp.types.includes('administrative_area_level_1')
    //                         )?.long_name;
    //                         const country = addressComponents?.find((comp) =>
    //                             comp.types.includes('country')
    //                         )?.long_name;
    //                         setResults(
    //                             res.results[0]?.postcode_localities?.map((place, index) => {
    //                                 return (
    //                                     <div key={index} style={{ padding: "12px 12px" }} onClick={() => { handleSelectLocation(place, { location: place, pincode: pinCode, city: city, state: state, country: country }) }}>
    //                                         <MdLocationPin />
    //                                         <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}>{`${place}, ${city}, ${state} ${pinCode}, ${country}`}</span>
    //                                     </div>
    //                                 )
    //                             })
    //                         )
    //                     }
    //                     else {
    //                         dispatch(
    //                             setAlert(
    //                                 {
    //                                     message: "No location found",
    //                                     show: true,
    //                                     color:'danger'
    //                                 }
    //                             )
    //                         )
    //                     }
    //                 })

    //             } else {
    //                 setResults(
    //                     res.results[0].address_components.map((place) => {
    //                         return (
    //                             <div key={place} style={{ padding: "12px 12px", width: "100vw", display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.05)", cursor: 'pointer' }} onClick={() => { handleSelectLocation(place.long_name) }}>
    //                                 <MdLocationPin />
    //                                 <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}>{place.long_name}</span>
    //                                 <MdAddLocation style={{ marginLeft: "auto" }} />
    //                             </div>
    //                         )
    //                     })
    //                 )
    //             }

    //         })
    //     }
    // }


    // const refreshUserLocation = async (place) => {
    //     try {
    //         let loggedUser = getLoggedUser();
    //         const res = await getUserDetails(loggedUser.id)
    //         if (res.user !== null && res.user !== undefined) {
    //             loggedUser.user_detail.other_cities = res?.user.user_detail?.other_cities;
    //             localStorage.setItem('user', JSON.stringify(loggedUser));
    //             dispatch(
    //                 setCurrentLocation(place)
    //             )
    //         }

    //     } catch (error) {

    //     }
    // }
    // const updateLocation = async (data) => {
    //     //   console.log('data', data)
    //     setIsUpdating(true)
    //     try {
    //         const res = await customRequest('user/updatelocation', data)
    //         // console.log('update',res)
    //         if (res.status === 'success') {
    //             await refreshUserLocation(data?.location)
    //         }
    //     } catch (error) {
    //         dispatch(
    //             setShowAlertPopup(
    //                 {
    //                     show: true,
    //                     title: "Alert",
    //                     message: "Something went wrong, please try again later.",
    //                 }
    //             )
    //         )
    //     } finally {
    //         setIsUpdating(false)
    //     }

    // }

    // const handleSelectLocation = async(place,result) => {
    //     if (location.state.type === "current_city") {
    //         dispatch(
    //             setCurrentCity(place)
    //         )
    //     } else if (location.state.type === "pincode") {
    //         dispatch(
    //             setPincode(place)
    //         )
    //     } else if (location.state.type === "other_cities") {
    //         dispatch(
    //             setOtherCities(place)
    //         )
    //     } else if (location.state.type === "search") {

    //         await updateLocation(result)

    //         // customRequest('user/updatelocation', { location: place });
    //         // let user = JSON.parse(localStorage.getItem('user'));
    //         // user.user_detail.other_cities += `,${place}`;
    //         // localStorage.setItem('user', JSON.stringify(user));
    //         // dispatch(
    //         //     setCurrentLocation(place)
    //         // )
    //     }
    //     navigate(-1)
    // }

    // const handleOnInputSearch = (val) => {
    //     if (searchBy === "pincode") {
    //         if (val.length === 6) {
    //             searchLocation(val);
    //         }
    //     } else {
    //         // setSearchString(val);
    //         clearTimeout(timer);
    //         setTimer(
    //             setTimeout(() => {
    //                 searchLocation(val);
    //             }, 1000)
    //         )
    //     }
    // }

    // const deleteSavedLocation = (location) => {

    //     customRequest('user/remove-user-location', { location: location }).then((res) => {
    //         let user = JSON.parse(localStorage.getItem('user'));
    //         let locs = user?.user_detail?.other_cities?.toString()?.split(',');
    //         locs = locs?.filter(item => item != location);
    //         setSavedLocations(locs);
    //         locs = locs?.join(',');
    //         user.user_detail.other_cities = locs;
    //         localStorage.setItem('user', JSON.stringify(user));
    //     })

    // }

    return (
        <div className='main_warp' style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px" }}>
            <MdArrowBack size={24} onClick={navigateBack} />
            <h4 style={{ fontFamily: "Open Sans", fontSize: 18, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Add or Update Locations</h4>
        </div>
        <div style={{ display: "flex", alignItems: "center", margin: "6px 12px", justifyContent: "space-between" }}>
            <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "location" ? "1px solid #6945B4" : "none" }} onClick={() => { setSearchBy('location'); setSearchText("") }}>
                By Location Name
            </div>
            <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "currentlocation" ? "1px solid #6945B4" : "none" }} onClick={() => { setSearchBy('currentlocation'); searchLocation('current');setIsLoading(true) }}>
                By Current Location
            </div>
            <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "pincode" ? "1px solid #6945B4" : "none" }} onClick={() => { setSearchBy('pincode'); setSearchText("") }}>
                By Pincode
            </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "6px 12px" }}>

          {searchBy !== "currentlocation" && <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, flex: 1 }}>
                {/* <i className="fas fa-search fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize", marginRight: 8 }}></i> */}
                {
                    searchBy === "location" ?
                        <input
                            value={searchText}
                            style={{ fontFamily: "Open Sans", fontSize: 15, border: "none", width: "84%", backgroundColor: "transparent" }}
                            placeholder="Enter location name"
                            onInput={onInputPlacePrediction}
                            loading={isPlacePredictionsLoading}
                        /> :
                        <input value={searchText} maxLength={searchBy === "pincode" ? 6 : 60} style={{ fontFamily: "Open Sans", fontSize: 15, border: "none", width: "84%", backgroundColor: "transparent" }} placeholder={searchBy === "pincode" ? "Enter pincode" : "Enter location"} onInput={(e) => { handleOnInputSearch(e.target.value) }} />}
            </div>}
        </div>
        <div style={{ display: "flex", flexDirection: "column", height: "calc(50vh)", overflowY: "auto" }}>
            {(isLoading || isPlacePredictionsLoading)
                ?
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Spinner color={Colors.primary} name="circle" />
                </div>
                :
                searchBy === "location" ?
                    placePredictions?.map((data, index) => {
                        const { place: location, city, country, pinCode: pincode, state } = getLocationDetails(data?.terms, 'prediction')
                        return (
                            <div key={index} style={{ padding: "12px 12px", width: "100vw", display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.05)", cursor: 'pointer' }} onClick={() => { handleSelectLocation(location, { location, pincode, city, state, country }) }}>
                                <MdLocationPin size={15} style={{minHeight:'15px',minWidth:"15px"}} />
                                <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}> {data.structured_formatting?.main_text || ""} {data.structured_formatting?.secondary_text || ""}</span>
                            </div>
                        )
                    })
                    : result}

        </div>
        <div style={{ display: "flex", flexDirection: "column",overflowY: "auto", padding: 12, borderTop: "1px solid rgba(0,0,0,0.1)" }}>
            <h4 style={{ fontSize: 16, fontWeight: "600" }}>Saved Location</h4>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {
                    savedLocations?.filter(location => location !== 'undefined' && location !== 'null' && location !== "")?.map((location, index) => {
                        return (
                            <span key={index} style={{ padding: "3px 6px", backgroundColor: "rgba(0,0,0,0.3)", borderRadius: 60, fontSize: 14, margin: 4 }}>
                                {location}
                                <MdClose style={{ cursor: "pointer", marginBottom: 2, marginLeft: 4 }} onClick={() => { !isDeleting && deleteSavedLocation(location) }} />
                            </span>
                        )
                    })
                }
            </div>
        </div>
        {(isDeleting || isUpdating) && <SpinnerOverlay />}
    </div>
        // <div style={{ display: "flex", flexDirection: "column" }}>
        //     <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px" }}>
        //         <MdArrowBack size={24} onClick={navigateBack} />
        //         <h4 style={{ fontFamily: "Open Sans", fontSize: 18, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Add or Update Locations</h4>
        //     </div>
        //     <div style={{ display: "flex", alignItems: "center", margin: "6px 12px", justifyContent: "space-between" }}>
               
        //         {/* <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "location" ? "1px solid #6945B4" : "none" }} onClick={() => { setSearchBy('location') }}>
        //             By Location Name
        //         </div> */}
        //         <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "currentlocation" ? "1px solid #6945B4" : "none" }} onClick={() => { setSearchBy('currentlocation'); searchLocation('current') }}>
        //             By Current Location
        //         </div>
        //         <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, fontSize: 10, cursor: "pointer", border: searchBy === "pincode" ? "1px solid #6945B4" : "none" }} onClick={() => { setSearchBy('pincode') }}>
        //             By Pincode
        //         </div>
        //         {/* <MdSearch style={{marginTop:4}}/> Search by location name
        //         <div style={{height:20,width:20,borderRadius:60,border:"2px solid #6945B4",marginLeft:"auto",display:"flex",alignItems:"center",justifyContent:"center"}}>
        //             {
        //                 !isSearchByPincode
        //                 ?
        //                 <div style={{height:13,width:13,borderRadius:60,backgroundColor:"#6945B4"}}></div>
        //                 :
        //                 null
        //             }
        //         </div> */}
        //     </div>
        //     <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "6px 12px" }}>
        //         <div style={{ padding: "6px 12px", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, flex: 1 }}>
        //             {/* <i className="fas fa-search fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize", marginRight: 8 }}></i> */}
        //             <input maxLength={searchBy === "pincode" ? 6 : 60} style={{ fontFamily: "Open Sans", fontSize: 15, border: "none", width: "84%", backgroundColor: "transparent" }} placeholder={searchBy === "pincode" ? "Enter pincode" : "Enter location"} onInput={(e) => { handleOnInputSearch(e.target.value) }} />
        //         </div>
        //         {/* <div style={{ cursor: "pointer", border: "1px solid #6945B4", borderRadius: 60, padding: "8px 8px", marginRight: 8, marginLeft: 10, display: "flex", alignItems: "center", justifyContent: "center" }} >
        //             <i className="fas fa-location fa-lg me-1" style={{ color: '#6945B4', textTransform: "capitalize" }}></i>
        //             <MdMyLocation color='#6945B4' />
        //         </div> */}
        //     </div>
        //     <div style={{ display: "flex", flexDirection: "column", height: "calc(50vh)", overflowY: "auto" }}>
        //         {
        //             isLoading
        //                 ?
        //                 <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        //                     <Spinner name="circle" />
        //                 </div>
        //                 :
        //                 null
        //         }
        //         {
        //             result
        //         }
        //     </div>
        //     <div style={{ display: "flex", flexDirection: "column", height: "22.5vh", overflowY: "auto", padding: 12, borderTop: "1px solid rgba(0,0,0,0.1)" }}>
        //         <h4 style={{ fontSize: 16, fontWeight: "600" }}>Saved Locations</h4>
        //         <div style={{ display: "flex", flexWrap: "wrap" }}>
        //             {
        //                 savedLocations.map((location, index) => {
        //                     return (
        //                         <span key={index} style={{ padding: "3px 6px", backgroundColor: "rgba(0,0,0,0.3)", borderRadius: 60, fontSize: 14, margin: 4 }}>
        //                             {location}
        //                             <MdClose style={{ cursor: "pointer", marginBottom: 2, marginLeft: 4 }} onClick={() => { deleteSavedLocation(location) }} />
        //                         </span>
        //                     )
        //                 })
        //             }
        //         </div>
        //     </div>
        // </div>
    )
}