import React from 'react';
import { MdLocationPin } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserDetails } from '../../../functions/services';
import { setCurrentLocation, setShowAlertPopup } from '../../../redux/dataSlice';
import { setCurrentCity, setOtherCities, setPincode } from '../../../redux/registrationSlice';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getLocationDetails, getLoggedUser } from '../../../functions/helper';
import { customRequestGETBare, customRequest } from '../../../functions/request';
import Config from '../../../config';


const useLocationSearch = () => {
    let loggedUser = getLoggedUser();
    const navigate = useNavigate();
    const [result, setResults] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchBy, setSearchBy] = React.useState("location");
    const [savedLocations, setSavedLocations] = React.useState(JSON.parse(localStorage.getItem('user')).user_detail.other_cities ? JSON.parse(localStorage.getItem('user')).user_detail.other_cities.split(',') : []);
    const [timer, setTimer] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [searchText, setSearchText] = React.useState("")
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: Config.MAP_API_KEY,
        debounce: 400,
    });


    function showAlertPopup(message) {
        dispatch(
            setShowAlertPopup({
                show: true,
                title: 'Alert',
                message,
            })
        );
    }


    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${position.coords.latitude},${position.coords.longitude}&sensor=true&key=${Config.MAP_API_KEY}`
                customRequestGETBare(URL)
                    .then((res) => {
                        setIsLoading(false);
                        setResults(
                            !!res?.results?.length && res.results.map((data, index) => {
                                const { place: location, city, country, pinCode: pincode, state } = getLocationDetails(data?.address_components);
                                const locationDetails = (location && city && country && state) ? (
                                    <div key={index}
                                        style={{ padding: "12px 12px", width: "100vw", display: "flex", alignItems: "center", borderBottom: "1px solid rgba(0,0,0,0.05)", cursor: 'pointer' }}
                                        onClick={() => { handleSelectLocation(location, { location, city, state, pincode, country }) }}>
                                        <MdLocationPin size={20} />
                                        <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}> {`${location}, ${city}, ${state} ${pincode ? pincode + ',' : ''} ${country}`}</span>
                                    </div>
                                ) : null;
                                return locationDetails;
                            })
                        );
                    })
                    .catch(err => {
                        setIsLoading(false);
                        showAlertPopup(err?.message)
                    });
            },
            (err) => {
                setIsLoading(false);
                showAlertPopup(err?.message)
            }
        );

    }


    const searchLocationByPincode = (search) => {
        setIsLoading(true)
        const URL = "https://maps.googleapis.com/maps/api/geocode/json?address=" + parseInt(search) + "&sensor=true&key=" + Config.MAP_API_KEY;
        customRequestGETBare(URL).then((res) => {
            setIsLoading(false);
            if (res !== undefined && res.status?.toLowerCase() === "ok") {
                const { city, country, pinCode, state } = getLocationDetails(res?.results[0]?.address_components)
                setResults(
                    res.results[0]?.postcode_localities?.map((place, index) => {
                        return (
                            <div key={index} style={{ padding: "12px 12px" }} onClick={() => { handleSelectLocation(place, { location: place, pincode: pinCode, city: city, state: state, country: country }) }}>
                                <MdLocationPin size={20} />
                                <span style={{ color: "black", fontSize: 15, marginLeft: 5 }}>{`${place}, ${city}, ${state} ${pinCode}, ${country}`}</span>
                            </div>
                        )
                    })
                )
            }
            else {
                dispatch(
                    setShowAlertPopup(
                        {
                            title: "Alert",
                            message: "No location found",
                            show: true,
                        }
                    )
                )
            }
        }).catch(e => {
            setIsLoading(false);
            showAlertPopup(e.message)
        })
    }

    const searchLocation = (search = "") => {
        setIsLoading(true)
        setResults(null);
        if (search === "current") {
            getCurrentLocation()
        } else if (searchBy === "pincode") {
            if (search.length !== 6) {
                showAlertPopup("Enter 6 digit pincode to search location")
                return;
            }
            searchLocationByPincode(search)
        }
        setIsLoading(false)

    }

    const refreshUserLocation = async (place) => {
        try {
            const res = await getUserDetails(loggedUser.id)
         //   console.log("userlocation::::=>>>>", res)

            if (res.user !== null && res.user !== undefined) {
                loggedUser.user_detail.other_cities = res.user.user_detail.other_cities;
                localStorage.setItem('user', JSON.stringify(loggedUser));
                localStorage.setItem('location_coords', JSON.stringify({ lat: res?.location?.latitude, lng: res?.location?.longitude }))
                dispatch(
                    setCurrentLocation(place)
                )
            }
        } catch (error) {

        }
    }


   
    const updateLocation = async (data) => {
        try {
            setIsUpdating(true);
            const updateResponse = await customRequest('user/updatelocation', data);

            if (updateResponse.status === 'success') {
                setTimeout(async () => {
                    await refreshUserLocation(data?.location)
                    setIsUpdating(false);
                }, 1000);

            } else {
                setIsUpdating(false);
                showAlertPopup("Something went wrong, please try again later.");
            }
        } catch (error) {
            console.error(error);
            setIsUpdating(false);
            showAlertPopup("Something went wrong, please try again later.");
        }
    };


    const handleSelectLocation = async (place, result) => {
        if (!place) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Something went wrong, please try again later.",
                    }
                )
            )
            return;
        }
        if (location.state.type === "current_city") {
            dispatch(
                setCurrentCity(place)
            )
        } else if (location.state.type === "pincode") {
            dispatch(
                setPincode(place)
            )
        } else if (location.state.type === "other_cities") {
            dispatch(
                setOtherCities(place)
            )
        } else if (location.state.type === "search") {
            await updateLocation(result)
        }
        navigate(-1)
    }


    const handleOnInputSearch = (val) => {
        setSearchText(val)
        if (searchBy === "pincode") {
            if (val.length === 6) {
                searchLocation(val);
            }
        } else {
            clearTimeout(timer);
            setIsLoading(true)
            setTimer(
                setTimeout(() => {
                    searchLocation(val);
                }, 1200)
            )
        }
    }

    const deleteSavedLocation = (location) => {
        setIsDeleting(true)
        customRequest('user/remove-user-location', { location: location }).then((res) => {
            if (res.status === 'success') {
                let user = JSON.parse(localStorage.getItem('user'));
                let locs = user.user_detail.other_cities.toString().split(',');
                locs = locs.filter(item => item != location);
                setSavedLocations(locs);
                locs = locs.join(',');
                user.user_detail.other_cities = locs;
                localStorage.setItem('user', JSON.stringify(user));
            }

        }).catch(e => {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Something went wrong, please try again later.",
                    }
                )
            )
        }).finally(() => setIsDeleting(false))

    }
    const navigateBack = () => {
        navigate(-1)
    }

    
    const onInputPlacePrediction = (evt) => {
        setSearchText(evt.target.value)
        getPlacePredictions({ input: evt.target.value });
    }

    return { result, isUpdating, setIsLoading, navigateBack, deleteSavedLocation, handleOnInputSearch, handleSelectLocation, placePredictions, location, isLoading, searchBy, isPlacePredictionsLoading, savedLocations, isDeleting, setSearchBy, searchLocation, searchText, setSearchText, onInputPlacePrediction }
}

export default useLocationSearch