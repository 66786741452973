import React from 'react';
import './styles.css'
import { customRequest } from '../../functions/request';
import { Link, useNavigate } from 'react-router-dom';
import LanguageAndMoodModal from '../../components/moodsAndLanguageModal';
import { useDispatch, useSelector } from "react-redux";
import { setAddInPosts, setPostsInBulk, setSelectedMood, setShowAlertPopup } from '../../redux/dataSlice';
import { MdLocationPin, MdMyLocation } from 'react-icons/md';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import Spinner from "react-spinkit";
import { setAlert } from '../../redux/alertSlice';
import { Colors } from '../../constants/colors';
import Today from './today';
import Greet from './today/greet';
import Posts from './posts';
import MoodAvatar from '../../components/moodAvatar';

let postOffset = 0

export default function HomeScreen() {
   const [activeTab, setActiveTab] = React.useState('community');
   //Moods
   const allMoods = useSelector((state) => state.data.allMoods);
   const viewMoods = useSelector((state) => state.data.viewMoods);
   const selectedMood = useSelector(state => state.data.selectedMood);
   const selectedLanaguageId = useSelector((state) => state.data.selectedLanguageId);
   const slicePosts = useSelector((state) => state.data.posts);
   const [isBottomLoading, setIsBottomLoading] = React.useState(false);
   const [allPostLoaded, setAllPostLoaded] = React.useState(false);
   const dispatch = useDispatch();
   const [isLoading, setIsLoading] = React.useState(true);
   const [viewTodaySection, setViewTodaySection] = React.useState(false)

   const navItems = [
      { id: 'today', label: 'Today', onClick: () => setViewTodaySection(true) },
      { id: 'community', label: 'Community', onClick: () => setViewTodaySection(false) },
      { id: 'challenges', label: 'Challenges', onClick: () => handleRedirect('/contestlist') },
      { id: 'newPost', label: 'New Post', onClick: () => handleRedirect('/newpost') },
      { id: 'profile', label: 'Profile', onClick: () => handleRedirect('/myprofile') },
   ];

   const [moodBadges, setMoodBadges] = React.useState(null);
   //let [postOffset, setPostOffset] = React.useState(0);

   //Location
   const currentLocation = useSelector(state => state.data.currentLocation);

   const navigate = useNavigate();


   React.useEffect(() => {
      getMoodBadges();
   }, [viewMoods, allMoods, selectedMood, selectedLanaguageId])



   React.useEffect(() => {
      //  console.log('get posts effect')
      postOffset = 0
      setAllPostLoaded(false)
      if (selectedMood && currentLocation && selectedLanaguageId) {
         getPosts();
      }
   }, [selectedMood, currentLocation, selectedLanaguageId])

   const getPosts = (addPosts) => {


      let data = {
         language: selectedLanaguageId,
      };

      //  data.offset = postOffset;
      if (addPosts) {
         postOffset = postOffset + 10
         data.offset = postOffset;
      }

      //console.log('oofset',data,postOffset)
      if (selectedMood !== "All") {
         data.mood = selectedMood;
      } else if (viewMoods.length > 0) {
         data.mood = viewMoods;
      }

      if (currentLocation !== "Everywhere") {
         data.location = currentLocation === "Nearme" ? "Nearme" : currentLocation;
         data.locationCoords = JSON.parse(localStorage.getItem('location_coords'));
      }

      if (addPosts && !allPostLoaded) {
         setIsBottomLoading(true);
         customRequest('post/allposts', data).then((res) => {
            setIsBottomLoading(false);
            if (res.posts.length === 0) {
               setAllPostLoaded(true);
            }
            //   console.log(res.posts.length)
            dispatch(setAddInPosts(res.posts));
         }).catch(e => {

         }).finally(() => {
            setIsBottomLoading(false)
         })
      } else if (postOffset === 0) {
         setIsLoading(true)
         customRequest('post/allposts', data).then((res) => {
            dispatch(setPostsInBulk(res.posts));
         }).catch(e => {
            dispatch(
               setAlert(
                  {
                     show: true,
                     message: "Some error occured.",
                     color: 'danger'
                  }
               )
            )
         }).finally(() => {
            setIsBottomLoading(false)
            setIsLoading(false)
         })

      }
   }


   // const renderPosts = () => {
   //    if (slicePosts === "loading") {
   //       setPosts(
   //          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "70vh", flex: 1 }}>
   //             <Spinner name="wave" />
   //          </div>
   //       )
   //    } else if (slicePosts.length === 0) {
   //       setPosts(
   //          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "70vh", flex: 1 }}>
   //             <img src={require("../../assets/empty_post.png")} style={{ height: 240, width: 240, objectFit: "contain" }} />
   //             {/* <p style={{ fontFamily: "Open Sans", fontSize: 15, textAlign: "center" }}>No any post.</p> */}
   //          </div>
   //       )
   //    } else {
   //       setPosts(
   //          slicePosts?.map((post) => {
   //             return (
   //                <PostCard key={post.id} post={post} />
   //             )
   //          })
   //       )
   //    }
   // }


   const handleSelectHomeMood = (mood) => {
      postOffset = 0
      dispatch(setSelectedMood(mood.id));
      // dispatch(setPostsInBulk('loading'));
   }

   const getMoodBadges = () => {
      setMoodBadges(
         allMoods.map((mood) => {
            if (viewMoods.includes(mood.id)) {
               return (
                  <a key={mood.id} className={selectedMood == mood.id ? "Tabsnavactive" : ""} onClick={() => { handleSelectHomeMood(mood) }} style={{ cursor: "pointer" }}>
                     <MoodAvatar image={mood.thumbnail_image} />
                     {mood.mood_name}
                     </a>
               )
            }
         })
      )
   }

   const handleRedirect = (route) => {
      if (localStorage.getItem('token')) {
         navigate(route)
      } else if (route === "/contestlist") {
         navigate(route)
      } else {
         let message = "Login to create new post.";
         if (route === "/myprofile") message = "Login to see your profile.";
         dispatch(
            setShowAlertPopup(
               {
                  show: true,
                  title: "Alert",
                  message: message,
                  showLoginBtn: true
               }
            )
         )
      }
   }



   const handleGetMorePostOnBottom = () => {
      if (!allPostLoaded) {
         setIsBottomLoading(true);
         getPosts(true);
      }
   }




   // React.useEffect(() => {
   //    renderPosts();
   // }, [slicePosts])


   return (
      <BottomScrollListener onBottom={handleGetMorePostOnBottom}>
         <div className="main_warp">
            <nav className="navbar sticky-top navbar-light mob-header-bar">
               <div className="w-100" style={{ display: "flex", alignItems: "center" }}>
                  <Link className="navbar-brand header-text me-auto" to="/home">
                     <img alt='localbol'
                        src={require("../../assets/logo.png")}
                        width="160"
                        height="30"
                        className="d-inline-block align-text-top"
                        style={{ mixBlendMode: "multiply" }}
                     />
                     {/* localbol */}
                  </Link>
                  {viewTodaySection ?
                     <Greet /> :
                     <>
                        <button
                           data-bs-toggle="modal"
                           data-bs-target="#languageModal"
                           type="button"
                           className="btn btn-secondary-light search-hide" style={{
                              padding: "7px 10px", marginLeft: "auto", marginRight: 10, background: Colors.primary, color: Colors.white,
                              borderColor: '#6945B4', borderWidth: 1,
                              alignItems: 'center'
                           }}>
                           {
                              currentLocation === "Nearme"
                                 ?
                                 <MdMyLocation size={15} style={{ marginRight: 5, marginBottom: 2 }} />
                                 :
                                 currentLocation === "Everywhere"
                                    ?
                                    <i className="fas fa-globe fa-lg me-1" style={{ textTransform: "capitalize", marginRight: 5 }}></i>
                                    :
                                    <MdLocationPin size={15} style={{ marginBottom: 2 }} />
                           }
                           {currentLocation}
                        </button>


                        <div className="bd-highlight col-1 px-0 align-self-center pe-1">
                           <div className="tabs-btn" data-bs-toggle="modal"
                              data-bs-target="#languageModal" >
                              <img alt='localbol' src="assets/images/NavIcons.svg" />
                           </div>
                        </div>
                     </>
                  }
                  <a
                     style={{ display: "none" }}
                     className="search-icons searchicons"
                     href="/#">
                     <img alt='localbol' src="assets/images/Arrow-Left.svg" />
                  </a>
               </div>
               <div className="mobsearch showsearch" style={{ display: "none" }}>
                  <div className="container-fluid px-0">
                     <form className="w-100">
                        <div className="row">
                           <div className="col-9 pe-0">
                              <div className="input-group flex-nowrap">
                                 <span className="input-group-text">
                                    <i className="fal fa-search"></i>
                                 </span>
                                 <input type="text" className="form-control" placeholder="Search for locations" />
                              </div>
                           </div>
                           <div className="col-3 text-center px-2">
                              <button type="submit" className="btn btn-search">Search</button>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div className="Subnav">
                  <div className="d-flex" style={{ overflow: "scroll", width: "100%" }}>
                     {navItems.map((item) => (
                        <div key={item.id} className={`mx-2 mt-1 ${activeTab === item.id && 'active-nav'}`} >
                           <p
                              id={`${item.id}-tab`}
                              type="button"
                              role="tab"
                              aria-controls={item.id}
                              aria-selected={activeTab === item.id}
                              onClick={() => {
                                 item.onClick();
                                 item.id === "newPost" ? setActiveTab(activeTab) : setActiveTab(item.id);
                              }}
                           >
                              {item.label}
                           </p>
                        </div>
                     ))}
                  </div>
               </div>
            </nav>
            {viewTodaySection ? <Today onShowCommunity={() => { setActiveTab('community'); setViewTodaySection(false) }} /> :
               <>
                  <div className="container px-0" >
                     <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                           <div className="homeTabs">
                              <div className="row">
                                 <div className="Tabsnav" style={{ maxWidth: 767, marginLeft: 15, paddingRight: 16 }}>
                                    <a className={selectedMood == "All" ? "Tabsnavactive" : ""} onClick={() => { dispatch(setSelectedMood('All')) }} style={{ cursor: "pointer" }}>All</a>
                                    {
                                       moodBadges
                                    }
                                 </div>
                              </div>
                           </div>
                           <div className="w-100" >
                              <Posts loading={isLoading} post={slicePosts} />
                              {/* {posts} */}
                              {
                                 isBottomLoading
                                    ?
                                    <div style={{ width: "100%", padding: "16px 0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                       <Spinner name='wave' color={Colors.primary} />
                                    </div>
                                    :
                                    null
                              }
                           </div>
                        </div>


                     </div>
                  </div>
                  <LanguageAndMoodModal />
               </>}
         </div>
      </BottomScrollListener>
   );
}