import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Brand from '../../components/Brand'
import CustomButton from '../../components/CustomButton'
import CustomInput from '../../components/CustomInput'
import GoogleButton from '../../components/GoogleButton'
import apiPath from '../../constants/apiPath'
import { Colors } from '../../constants/colors'
import { saveUser, saveUserToken } from '../../functions/helper'
import { customRequest } from '../../functions/request'
import { setShowAlertPopup } from '../../redux/dataSlice'

const LoginWithEmail = () => {
  const [identifier, setIdentifier] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { redirectToContestId } = useSelector(state => state.data)


  const handleLogin = () => {

    if (!identifier || !password) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "All fields are required",
            //  registerBtn: true
          }
        )
      )
      return;
    }
    setLoading(true)
    const body = {
      email: identifier,
      password
    }
    customRequest(apiPath.emailLogin, body)
      .then(res => {
        if (res.status === 'success') {
          saveUser(res.user)
          saveUserToken(res.token);
          if (redirectToContestId) {
            navigate(`/contest/${redirectToContestId}'`)
          } else {
            navigate('/home');

          }
        }
        if (res.status === 'failed') {
          dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: res.message,
                registerBtn: true
              }
            ))
        }
      })
      .catch(e => {
        console.log(e)
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: e.message,
              //  registerBtn: true
            }
          ))
      })
      .finally(() => setLoading(false))
  }




  return (
    <div className="pt-4 " style={{
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      paddingTop: 10,
      height: '100vh',
      overflow: 'scroll',
    }} >
      <div className='p-4 m-4'>

<img src={require("../../assets/main_logo.png")} style={{ height: "25vh", width: "100%", zIndex: 2, objectFit: "contain" }} />
</div>
      {/* <Brand /> */}
      <div style={{ height: "56%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
        <div className='p-4' style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
          <CustomInput value={identifier} onChange={(e) => setIdentifier(e.target.value)} placeholder={"Username or Email or WhatsApp number*"} />
          <CustomInput type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={"Password*"} className={"mt-2"} />
          <CustomButton onClick={handleLogin} disabled={loading ? true : false} label={"SIGN IN"} className={"btn-block mb-2"} />
        
          <CustomButton onClick={() => navigate('/create-account')} label={"Create New Account"} className={"btn-block"} color={Colors.secondary} />
          {/* <h6 className='text-center mt-3'>Forgot Password?</h6> */}
          {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <hr style={{ flex: 2 }} />
            <p style={{ color: Colors.grey, flex: 0.5, textAlign: 'center', marginTop: 10 }}>OR</p>
            <hr style={{ flex: 2 }} />
          </div>
          <GoogleButton label={'Sign up with'} onClick={() => navigate("/signup-with-google")} /> */}

        </div>
      </div>
      <p className='text-center m-0' style={{ right: 0, left: 0, fontSize:"0.8rem"}}>
        By using Fittestclub, You agree to our  <Link to="/terms-and-condition" style={{ color: Colors.primary, fontWeight: '600' }}>Terms & conditions</Link>,and <Link to="/privacy-policy" style={{ color: Colors.primary, fontWeight: '600' }}>Privacy policy</Link>.
      </p>
    </div>
  )
}

export default LoginWithEmail
